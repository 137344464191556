import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView, Image, TouchableOpacity } from 'react-native';
import { commonStyle } from '../components/style';
import { Octicons, Feather, Entypo } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-community/async-storage';

const Settings = () => {

    const { i18n, t } = useTranslation();

    const [isLogin, setIsLogin] = useState(false);

    const navigation = useNavigation();

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );

    const init = async () => {
        const userinfo = await AsyncStorage.getItem('userinfo');
        const cookies = await AsyncStorage.getItem('cookies');
        if (!userinfo) {
            navigation.navigate('Login');
        } 
    }

    const goToHomeScreen = () => {
        // navigation.navigate('Home');
        navigation.navigate('MainNav', { screen: 'Home' });
        // navigation.goBack()
    }

    const goToSecurityQuestionScreen = () => {
        navigation.navigate('SecurityQuestion');
    }

    const goToEditPasswordScreen = () => {
        navigation.navigate('EditPassword');
    }

    const signOut = async () => {
        console.log('退出')
        await AsyncStorage.removeItem('userinfo');
        await AsyncStorage.removeItem('cookies');
        await AsyncStorage.removeItem('websiteInfoStorage');
        navigation.navigate('MainNav', { screen: 'Home' });
    }

    return (
        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '90%' }}
            >
                <View style={styles.sectionContainer}>

                    <TouchableOpacity onPress={goToEditPasswordScreen}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Feather name="edit" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('settings')}{t('loginPassword')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>


                    {/* <TouchableOpacity onPress={goToSecurityQuestionScreen}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Feather name="edit" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('settings')}{t('securityQuestion')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> */}

                    <TouchableOpacity onPress={signOut}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Octicons name="sign-out" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('out')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>


                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
    },
    sectionContainer: {
        backgroundColor: '#222430',
        marginBottom: 20,
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    section: {
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 16,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    iconBadge: {
        fontSize: 16,
        // fontWeight: 'bold',
    },
    name: {
        fontSize: 20,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2
    },
    switchSection: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    country: {
        // marginRight: 2,
        color: '#828B9F'
    },
    switch: {
        // backgroundColor: '#ccc',
        // padding: 5,
        // borderRadius: 5,
        // color: '#828B9F'
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F'
    },
    icon: {
        // backgroundColor: '#ccc',
        // width: 30,
        // height: 30,
        // marginRight: 10,
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    label: {
        fontSize: 16,
        color: '#828B9F'
        // color: commonStyle.textWhiteColor
    },
    subText: {
        fontSize: 14,
        marginRight: 10,
        color: '#828B9F'
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    iconInner: {
        backgroundColor: '#ccc',
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 30,
        height: 30
    },
    flexColumn: {
        flexDirection: 'column',
        flex: 1
    }
});

export default Settings;