import React, { useEffect } from 'react';
import Exchange from './exchange';
import { navigateRequireLogin } from '../components/util';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { commonStyle } from '../components/style';

const ExchangeDefault = () => {

  const navigation = useNavigation();

  useEffect(() => {

  }, []);


  useFocusEffect(
    React.useCallback(() => {
      const route = {
        name: 'Exchange',
        params: { currency: 'BTC' },
      };
      navigateRequireLogin(navigation, route);
    }, [])
  );



  return <View style={styles.container}>
    {/* <Exchange /> */}
  </View>;
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: commonStyle.bgColor,
    width: '100%',
    height: '100%',
  },

})

export default ExchangeDefault;