import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
// import * as Notifications from 'expo-notifications';
// import * as Permissions from 'expo-permissions';
import { getLetters } from '../api/apis';
import { navigateRequireLogin } from '../components/util';


// 只导入 Permissions 和 Notifications 以供非 web 平台使用
let Permissions;
let Notifications;

if (Platform.OS !== 'web') {
  Permissions = require('expo-permissions');
  Notifications = require('expo-notifications');
}

const MessageNotifications = () => {
  const navigation = useNavigation();
  const [notificationAlert, setNotificationAlert] = useState(false);
  const [announcement, setAnnouncement] = useState([]);
  const [notification, setNotification] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    getAnnouncement();
    getNotification();

    if (Platform.OS !== 'web') {
      checkNotificationPermission();
      const unsubscribe = navigation.addListener('beforeRemove', checkNotificationPermission);
      return unsubscribe;
    }
  }, []);

  // 公告
  const getAnnouncement = () => {
    getLetters(1, 1)
      .then(response => {
        console.log(response);
        if (response.status === '1') {
          const updateData = response.Data.map(data => {
            const time_year = new Date(parseInt(data.time_year) * 1000).toLocaleString();
            return { ...data, time_year: time_year };
          });
          setAnnouncement(updateData);
        }
      })
      .catch(error => {
        // console.error(error);
      });
  };

  // 通知
  const getNotification = () => {
    getLetters(1)
      .then(response => {
        console.log(response);
        if (response.status === '1') {
          const updateData = response.Data.data.map(data => {
            const send_time = new Date(parseInt(data.send_time) * 1000).toLocaleString();
            console.log(data.send_time, send_time);
            return { ...data, send_time: send_time };
          });
          setNotification(updateData);
        }
      })
      .catch(error => {
        // console.error(error);
      });
  };

  async function checkNotificationPermission() {

      const { status } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      if (status === 'granted') {
        // 用户已经授予通知权限
        console.log('已授权');
        setNotificationAlert(false);
        return true;
      } else {
        console.log('未授权');
        setNotificationAlert(true);
        // 用户尚未授予通知权限
        return false;
      }
    
  }

  // 请求通知权限
  const requestNotificationPermission = async () => {

      const { status } = await Notifications.requestPermissionsAsync();
      if (status === 'granted') {
        // 用户已授予通知权限
        console.log('用户已授予通知权限');
        setNotificationAlert(false);
      } else {
        // 用户未授予通知权限
        console.log('用户未授予通知权限');
      }
    
  };

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        {notificationAlert && (
          <View style={styles.notificationContent}>
            <View>
              <Text style={styles.notificationTitle}>{t('enableSystemNotifications')}</Text>
              <Text style={styles.notificationSubtitle}>{t('receiveSystemMessagesinRealTime')}</Text>
            </View>
            <View style={styles.notificationActions}>
              <TouchableOpacity onPress={() => requestNotificationPermission()}>
                <Text style={styles.startText}>{t('startNow')}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setNotificationAlert(false)}>
                <Text style={styles.closeText}>x</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        <View>

          <TouchableOpacity onPress={() => navigateRequireLogin(navigation, { name: 'Message', params: { title: t('systemAnnouncement'), type: 1 } })}>
            <View style={styles.notificationItem}>
              <View style={styles.notificationItemLeft}>
                <Image style={styles.image} source={require('../../assets/imgs/msg/bell.png')} />
                <View style={styles.notificationTextContainer}>
                  <Text style={styles.notificationTitle}>{t('systemAnnouncement')}</Text>
                  {announcement.length > 0 && <Text style={styles.notificationCount} numberOfLines={2} ellipsizeMode="tail">{announcement[0].title}</Text>}
                </View>
              </View>
              {announcement.length > 0 && <View style={styles.notificationItemRight}>
                <Text style={styles.notificationTime}>{announcement[0].time_year}</Text>
                {/* <View style={styles.circle}>
                  <Text style={styles.circleText}>9</Text>
                </View> */}
              </View>}
            </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigateRequireLogin(navigation, { name: 'Message', params: { title: t('customerServiceMessage'), type: 2 } })}>
            <View style={styles.notificationItem}>
              <View style={styles.notificationItemLeft}>
                <Image style={styles.image} source={require('../../assets/imgs/msg/customer.png')} />
                <View style={styles.notificationTextContainer}>
                  <Text style={styles.notificationTitle}>{t('customerServiceMessage')}</Text>
                  {notification.length > 0 && <Text style={styles.notificationCount} numberOfLines={2} ellipsizeMode="tail">{notification[0].title}</Text>}
                </View>
              </View>
              {notification.length > 0 && <View style={styles.notificationItemRight}>
                <Text style={styles.notificationTime}>{notification[0].send_time}</Text>
                {notification[0].status == 1 && <View style={styles.circle}>
                  {/* <Text style={styles.circleText}></Text> */}
                </View>}
              </View>}
            </View>
          </TouchableOpacity>

        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: commonStyle.bgColor,
    alignItems: 'center',
  },
  contentContainer: {
    width: '95%',
  },
  notificationContent: {
    backgroundColor: '#2C3445',
    borderRadius: 5,
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notificationActions: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationTitle: {
    color: '#fff',
  },
  notificationSubtitle: {
    color: '#13B777',
    fontSize: 12,
  },
  startText: {
    color: '#fff',
    backgroundColor: '#13B777',
    lineHeight: 25,
    borderRadius: 20,
    height: 25,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontSize: 12,
  },
  closeText: {
    color: '#fff',
    marginLeft: 10,
  },
  notificationItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  notificationItemLeft: {
    flexDirection: 'row',
  },
  image: {
    width: 30,
    height: 30,
  },
  notificationTextContainer: {
    flexDirection: 'column',
    marginLeft: 5,
  },
  notificationCount: {
    color: '#7E879B',
    fontSize: 12,
    overflow: 'hidden',
  },
  notificationTime: {
    color: '#707070',
    fontSize: 10,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 50,
    backgroundColor: '#F5465C',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    position: 'relative',
    top: -10,
    left: -15
  },
  circleText: {
    color: '#fff',
    fontSize: 8,
  },
});

export default MessageNotifications;