import React, { useRef, useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, Clipboard } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '@expo/vector-icons';
import { betcenterBetrecord } from '../api/apis';
import { formatTimestampToDateTime, unitconversion } from '../components/util'
import Toast, { DURATION } from 'react-native-easy-toast'

const App = (props) => {

    const { t } = useTranslation();

    const toastRef = useRef(null);

    const item = props.route.params.item

    const formattedDateString = formatTimestampToDateTime(item.Bet_time); // 将日期对象转换为字符串

    console.log(item)

    useEffect(() => {
        // 初始化数据

    }, []);


    // 复制
    const handleCopy = () => {
        Clipboard.setString(item.Bet_slip_number);
        toastRef.current.show(t('success'));
    }

    return (
        <View style={styles.container}>
            <View>
                <Text style={[styles.text, { fontSize: 18, textAlign: 'center', marginTop: 10 }]}>{item.name} {item.Lotterly_code}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.text}>{t('bettingOrderNumber')}：</Text>
                <Text style={styles.text}>{item.Bet_slip_number}</Text>

            </View>
            <TouchableOpacity onPress={handleCopy}>
                <Text style={styles.copyText}>{t('copyOrderNumber')}</Text>
            </TouchableOpacity>
            <View style={styles.row}>
                <Text style={styles.text}>{t('agentReport.2')}：</Text>
                <Text style={styles.text}>{unitconversion(item.Bet_amount)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.text}>{t('time')}：</Text><Text style={styles.text}>{formattedDateString}</Text>
            </View>


            <View style={styles.row}>
                <Text style={styles.text}>{t('prize')}：</Text>


                {item.State_Winning == '3' &&
                    <Text style={styles.itemTextGray}>
                        {t('waitingLottery')}
                    </Text>
                }
                {item.State_Winning == '2' &&
                    <Text style={styles.itemTextRed}>
                        {t('notWinningTheLottery')}
                    </Text>
                }
                {item.State_Winning == '1' &&
                    <Text style={styles.itemTextGreen}>
                        +{(Number(item.Winning_amount)/100)}
                    </Text>
                }


            </View>

            <View style={styles.row}>
                <Text style={styles.text}>
                    {t('purchase')}：{item.BetNum == 'up' ? t('buyUp') : t('buyDown')}
                </Text>
            </View>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={300}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        paddingHorizontal: 20,

    },
    text: {
        color: '#fff',
        fontSize: 16
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    copyText: {
        color: '#fff',
        backgroundColor: '#13B777',
        width: 150,
        padding: 6,
        borderRadius: 5,
        textAlign: 'center',
        marginTop: 10
        // margin: 10,
    },
});

export default App;