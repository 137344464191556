import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { showAlert } from '../components/showAlert';
import TopNavigationBar from '../components/topNavigationBar';
import { commonStyle } from '../components/style';

const ContractDetail = (props) => {
    const navigation = useNavigation();
    const { t } = useTranslation();

    const [data, setData] = useState(props.route.params.item);

    console.log(data);

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: data.currency,
            headerStyle: {
                backgroundColor: commonStyle.black,
                borderBottomWidth: 0
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);

    useEffect(() => {
        // 初始化数据
        console.log('初始化');
    }, []);


    // 确认下单
    const goToConfirmOrderScreen = (value) => {
        navigation.navigate('ConfirmOrder',{currency:data.currency,upDown:value});
    }

    const renderDetailItem = (title, value, key = '') => {
        let valueText = <Text style={styles.itemTextWhite}>{value}</Text>

        //合约类型
        if (key == 'risefall') {
            if (data.risefall == 1) {
                valueText = <Text style={styles.itemTextGreen}>{t('buyUp')}</Text>
            }
            if (data.risefall == 2) {
                valueText = <Text style={styles.itemTextRed}>{t('buyDown')}</Text>
            }
        }

        //盈亏金额
        if (key == 'plAmount') {
            if (value > 0) {
                valueText = <Text style={styles.itemTextGreen}>{value}</Text>
            } else {
                valueText = <Text style={styles.itemTextRed}>-{value}</Text>
            }
        }

        return (
            <View style={styles.itemContainer}>
                <View style={styles.itemRowContainer}>
                    <Text style={styles.itemTextWhite}>{title}</Text>
                    {valueText}
                </View>
            </View>
        );
    };

    const bottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>
                <TouchableOpacity style={styles.button} onPress={() => goToConfirmOrderScreen('up')}>
                    <View style={styles.buttonLeft}>
                        <Text style={styles.bottomText}>{t('buyUp')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={() => goToConfirmOrderScreen('down')}>
                    <View style={styles.buttonRight}>
                        <Text style={styles.bottomText}>{t('buyDown')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            {/* <View style={styles.title}>
                <View style={styles.titleContainer}>
                    <View style={styles.titleLeft}>
                        <Text style={styles.titleText}>{t('secondContractRecords')}</Text>
                    </View>
                    <TouchableOpacity>
                        <Image style={styles.image} source={require('../../assets/imgs/contract/toprRightIcon.png')} />
                    </TouchableOpacity>
                </View>
            </View> */}
            <TopNavigationBar title={`${data.currency + ' ' +t('contractDetails')}`} />
            <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
                {renderDetailItem(t('contractAmount'), data.money + data.currency)}
                {renderDetailItem(t('contractDuration'), data.time)}
                {renderDetailItem(t('contractType'), data.risefall, 'risefall')}
                {renderDetailItem(t('contractStatus'), data.statusText)}
                {renderDetailItem(t('proposalUnitPrice'), data.unitPrice)}
                {renderDetailItem(t('proposalTime'), data.time)}
                {renderDetailItem(t('averageUnitPrice'), data.unitPrice)}
                {renderDetailItem(t('averageTime'), data.time)}
                {renderDetailItem(t('profitLoss'), data.unitPrice + data.currency, 'plAmount')}
                <View style={{ height: 80 }}></View>
            </ScrollView>
            {bottomButtons()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.black
    },
    title: {
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#121420',
        height: 40,
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        marginHorizontal: 'auto',
    },
    titleLeft: {
        flex: 1,
        justifyContent: 'center',
    },
    titleText: {
        textAlign: 'left',
        color: commonStyle.textWhiteColor,
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    itemContainer: {
        flexDirection: 'column',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#2C3445',
        marginTop: 10,
        // width: '90%',
        borderRadius: 5,
        marginHorizontal: 'auto',
        marginHorizontal:20,
        height: 42,
    },
    itemRowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextWhite: {
        color: commonStyle.textWhiteColor,
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#222430',
    },
    button: {
        flex: 1,
        paddingVertical: 15,
        alignItems: 'center',
    },
    buttonText: {
        fontSize: commonStyle.btnFont,
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width:'80%',
        justifyContent:"center",
        // paddingHorizontal: 60,
        borderRadius: 10
    },
    buttonRight: {
        backgroundColor: '#F5465C',
        width:'80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5
    },
    image: {
        width: 40,
        height: 40,
    },
});

export default ContractDetail;