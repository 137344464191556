import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView, Image, ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast, { DURATION } from 'react-native-easy-toast'
import { useNavigation } from '@react-navigation/native';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { getUserbalance, getwithdrawDetermine, getHuobiPrice,getSymbolPrice } from '../api/apis';
import { unitconversion,navigateRequireLogin} from '../components/util'

// getUserbalance  type：all 所有的余额  loanratio借款比例	loanamount借款金额 	bettingratio投注比例  其他的对应的币种资产
// getwithdrawDetermine  channel参数 ：withdraw 一般提现  earnWallet 提现到钱包  loan借款  exchange兑换

const App = () => {


    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View>
                    <TouchableOpacity onPress={() => goToRecordScreen()}>
                        <Text style={{ color: '#fff', marginRight: 10, fontSize: 16 }}>{t('record')}</Text>
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [navigation]);


    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [userbalance, setUserbalance] = useState('0.00');  // 用户余额
    const [num, setNum] = useState('0');

    const [price, setPrice] = useState(0);  // 币价格
    const [estimatedPrice, setEstimatedPrice] = useState(0);  // 预估价格
    
    const [financialPassword, setFinancialPassword] = useState(''); // 资金密码

    const [coinList, setCoinList] = useState([
        { name: 'BTC', num: 0 },
        { name: 'ETH', num: 0 },
        { name: 'TRX', num: 0 },
        { name: 'BNB', num: 0 },
        { name: 'USDC', num: 0 }
    ]);
    const [coinSelect, setCoinSelect] = useState('BTC');
    const [coinNumSelect, setCoinNumSelect] = useState(0);

    const navigation = useNavigation();

    useEffect(() => {
        init()
    }, []);

    const init = async () => {

        let balance = 0;
        getUserbalance().then(res => {
            // console.log('余额',res.Data.BackData)
            balance = Number(res.Data.BackData)
            setUserbalance(balance)
        })


        const symbolPrice = await getSymbolPrice(coinSelect);
        if (symbolPrice) {
            setPrice(symbolPrice)
        }else{
            const huobiPrice = await getHuobiPrice(coinSelect);
            if (huobiPrice) {
                setPrice(huobiPrice)
            }
        }
      
        
        getUserbalance({ type: "all" }).then(res => {

            const coin = res.Data.other
            const upateCoinList = coinList.map((data) => {
                let coinNum = 0;
                if (data.name === "BTC") {
                    coinNum = coin.BTC/100
                    setCoinSelect('BTC')
                    setCoinNumSelect(parseFloat(coinNum))
                }
                if (data.name === "ETH") {
                    coinNum = coin.ETH/100
                }
                if (data.name === "TRX") {
                    coinNum = coin.TRX/100
                }
                if (data.name === "BNB") {
                    coinNum = coin.BNB/100
                }
                if (data.name === "USDC") {
                    coinNum = coin.USDC/100
                }
            
                return {
                    ...data,
                    num: parseFloat(coinNum),
                };

            });

            setCoinList(upateCoinList)
        })

    }

    const goToRecordScreen = () => {
        const route = {
            name: 'QuickExchangeRecord',
            params: {},
        };
        navigateRequireLogin(navigation, route);

    };


    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const handleFinancialPasswordChanged = input => {
        setFinancialPassword(input)
    };

    const handleChanged = input => {
        if(isNaN(input)){
            return
        }
        // if(userbalance == 0){
        //     return;
        // }
        if(type == 1){
            if(coinNumSelect == 0){
                return;
            }
            if(Number(input) > Number(coinNumSelect)){
                return
            }
            if(Number(input) > 0){
                setEstimatedPrice((input * price).toFixed(6))
            }
        }else{
            if(userbalance == 0){
                return;
            }
            if(Number(input) > Number(userbalance)/100){
                return
            }

            setEstimatedPrice((Number(input) * (1/price)).toFixed(6))
        }

        setNum(input)
    };

    const exchangeAll = () => {
        // if(userbalance == 0){
        //     setEstimatedPrice(0)
        //     return;
        // }
        if(type == 1){
            if(coinNumSelect == 0){
                setEstimatedPrice(0)
                return;
            }
            setNum(coinNumSelect.toString())
            setEstimatedPrice((coinNumSelect * price).toFixed(6))
        }else{

            if(userbalance == 0){
                return;
            }

            setNum((userbalance/100).toString())
            setEstimatedPrice(((userbalance/100) * (1/price)).toFixed(6))
        }
    };

    const handleSelect = async (item) => {
        closeModal()
        setNum('0')
        setEstimatedPrice('0')
        setCoinSelect(item.name)
        setCoinNumSelect(item.num)

        const symbolPrice = await getSymbolPrice(coinSelect);
        if (symbolPrice) {
            setPrice(symbolPrice)
        }else{
            const huobiPrice = await getHuobiPrice(item.name);
            if (huobiPrice) {
                setPrice(huobiPrice)
            }
        }
    
    }

    const handleConfirm = async () => {
        if(num == 0){
            return;
        }
        if(financialPassword == ''){
            toastRef.current.show(t('pleaseEnterFinancialPassword'));
            return;
        }

        setIsLoading(true);

        // TRX,BNB,BTC,ETH,USDC 兑换 usdt 时 channel传 exchange_sell
        // 正常余额兑换 TRX,BNB,BTC,ETH,USDC  时 channel传 exchange
        const params = {
            Withdrawal_Amount: num,
            channel: type == 1 ? 'exchange_sell' : 'exchange',
            safe: financialPassword,
            Bank_card: coinSelect
        }

        try {
            const response = await getwithdrawDetermine(JSON.stringify(params));
            setIsLoading(false);
            // console.log(response)
            if (response.status == '1') {
                toastRef.current.show(response.msg);
                setNum('0');
                setFinancialPassword('');
                init()
            }else{
                toastRef.current.show(response.Data.msg);
            }
        } catch (error) {
            // console.log(error)
            setIsLoading(false);
            toastRef.current.show(t('fail'));
        }
    };

    return (

        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '95%', marginVertical: 20 }}
            >
                <View style={styles.form}>

                    {type == 1 &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'left' }}>
                                <TouchableOpacity onPress={openModal}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ color: '#fff' }}>{coinSelect}</Text>
                                        <AntDesign name="down" size={16} color={commonStyle.green} style={{ marginTop: 3 }} />
                                    </View>
                                </TouchableOpacity>

                                <Text style={{ color: 'gray' }}>{coinNumSelect}</Text>
                            </View>
                            <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                                if (type == 1) {
                                    setType(2)
                                } else {
                                    setType(1)
                                }
                                setNum('0')
                                setEstimatedPrice('0')
                            }}>
                                <FontAwesome name="exchange" size={18} color={commonStyle.green} />
                            </TouchableOpacity>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                                <Text style={{ color: '#fff', textAlign: 'right' }}>USDT</Text>
                                <Text style={{ color: 'gray' }}>{unitconversion(userbalance)}</Text>
                            </View>
                        </View>
                    }


                    {type == 2 &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'left' }}>

                                <Text style={{ color: '#fff' }}>USDT</Text>

                                <Text style={{ color: 'gray' }}>{unitconversion(userbalance)}</Text>
                            </View>
                            <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                                if (type == 1) {
                                    setType(2)
                                } else {
                                    setType(1)
                                }
                                setNum('0')
                                setEstimatedPrice('0')
                            }}>
                                <FontAwesome name="exchange" size={18} color={commonStyle.green} />
                            </TouchableOpacity>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                                <TouchableOpacity onPress={openModal}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ color: '#fff', textAlign: 'right' }}>{coinSelect}</Text>

                                        <AntDesign name="down" size={16} color={commonStyle.green} style={{ marginTop: 3 }} />
                                    </View>
                                </TouchableOpacity>
                                <Text style={{ color: 'gray' }}>{coinNumSelect}</Text>
                            </View>
                        </View>
                    }


                    <SpliteLine />
                    <View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                            <Text style={{ color: '#fff' }}>{t('exchangeQuantity')}</Text>

                            {type == 1 && 
                            <Text style={{ color: 'gray' }}>1 {coinSelect} = {price} USDT</Text>
                            }
                            {type == 2 && 
                            <Text style={{ color: 'gray' }}>1 USDT = {(1/price).toFixed(6)} {coinSelect} </Text>
                            }

                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <TextInput
                                style={[
                                    styles.input,
                                ]}
                                onChangeText={handleChanged}
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green}
                                value={num} />

                            <View style={{ flexDirection: 'row' }}>

                                {type == 1 && 
                                <Text style={{ color: '#fff' }}>{coinSelect} | </Text>
                                }

                                {type == 2 && 
                                <Text style={{ color: '#fff' }}>USDT | </Text>
                                }
                                <TouchableOpacity onPress={exchangeAll}>
                                    <Text style={{ color: commonStyle.green }}>{t('all')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>
                    <SpliteLine />
                    <Text style={styles.label}>{t('financialPassword')}</Text>
                    <TextInput
                        style={[
                            styles.input,
                        ]}
                        onChangeText={handleFinancialPasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholder={t('pleaseEnterFinancialPassword')}
                        placeholderTextColor={commonStyle.green}
                        value={financialPassword}
                    />
                    <SpliteLine />

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <Text style={{ color: '#fff' }}>{t('estimate')}</Text>
                        {type == 1 && 
                        <Text style={{ color: 'gray' }}>{estimatedPrice} USDT</Text>
                        }
                        {type == 2 && 
                        <Text style={{ color: 'gray' }}>{estimatedPrice} {coinSelect}</Text>
                        }
                    </View>

                    <TouchableOpacity style={styles.button} onPress={handleConfirm} disabled={isLoading}>
                        <Text style={styles.buttonText}>{t('confirm')}</Text>
                    </TouchableOpacity>


                </View>

            </ScrollView>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >
                            <View>
                                {coinList.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleSelect(item)}>
                                        <View style={styles.card} >
                                            <Text style={[styles.modalText]}>{item.name}: {item.num}</Text>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        // width: '80%',
        paddingHorizontal: 10,
        justifyContent: 'center',

    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: commonStyle.green,
        marginBottom: 4,
        marginTop: 2,
        height: 25,
        borderWidth: 0,
        borderBottomColor: 'transparent',
        flex: 1
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '90%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#fff',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        // backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
    image: {
        width: 80,
        height: 80
    }
});

export default App;