import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, ActivityIndicator, Platform, Image, Button, Alert } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
import { amountUnit, unitconversion } from '../components/util'
import { getWebsiteInfo, getRecharge } from '../api/apis';
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import { useNavigation } from '@react-navigation/native';
import { WebView } from 'react-native-webview';

const ThirdpartyPay = (props) => {

    const { t, i18n } = useTranslation();
    const toastRef = useRef(null);
    const navigation = useNavigation();

    const [amount, setAmount] = useState('');
    const [amountExchangerate, setAmountExchangerate] = useState(0);
    const [unit, setUnit] = useState('');
    const [curr, setCurr] = useState('');
    const [exchangerate, setExchangerate] = useState('');
    const [extractedNumber, setExtractedNumber] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [amFixed, setAmFixed] = useState([]);
    const [thirdName, setThirdName] = useState('');
    const [payUrl, setPayUrl] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const thirdpartyPayData = props.route.params.thirdpartyPayData

        setPriceMin(thirdpartyPayData.price_min)
        setPriceMax(thirdpartyPayData.price_max)

        setThirdName(thirdpartyPayData.third_name)
        setAmFixed(thirdpartyPayData.am_fixed)

        init()
    }, []);

    const init = async () => {
        console.log('page:ThirdpartyPay')
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            // console.log(curr, i18n.language)
            setCurr(curr)
            setExchangerate(response.Data.exchangerate)

            var parts = response.Data.exchangerate.split(":");
            var extractedNumber = parts[1];
            setExtractedNumber(extractedNumber)

            const au = amountUnit(curr, i18n.language)
            // console.log(au.unit)
            // au.icon
            setUnit(au.unit)

        }
    }

    const handleAmoutClick = (value) => {
        setAmount(value)
    }

    const handleAmount = (value) => {
        setAmount(value)
    }

    const handleSubmit = async () => {
        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount.toString())) {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }

        if (Number(amount)  >= Number(priceMin) && Number(amount)  <= Number(priceMax)) {
            var parts = exchangerate.split(":");
            var extractedNumber = parts[1];
            setAmountExchangerate(Number(extractedNumber) * Number(amount))
            const params = `{"Money":"${amount}","Exchangerate":"${exchangerate}","NickName":"${thirdName}","id":"10"}`

            // console.log(params)

            setLoading(true)
            const response = await getRecharge(params, 3)
            setLoading(false)
            if (response.status == '1') {
                setPayUrl(response.item.pay_url)
                console.log(response.item.pay_url)
            } else {
                console.log(response)
                showAlert(t('fail'))
            }
        } else {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }
    }

    return (
        <View style={styles.container}>

            {Platform.OS == 'web' && payUrl && <iframe src={payUrl} width="100%" height="100%" style={{ border: '0px' }} />}
            {Platform.OS != 'web' && payUrl &&
                <WebView
                    source={{ uri: payUrl }}
                    style={{ flex: 1 }}
                    onError={(syntheticEvent) => {
                        const { nativeEvent } = syntheticEvent;
                        console.error('WebView error: ', nativeEvent);
                    }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                    allowFileAccessFromFileURLs={true}
                    allowUniversalAccessFromFileURLs={true}
                    mixedContentMode="always"
                    originWhitelist={['*']}
                    scalesPageToFit={true}
                    thirdPartyCookiesEnabled={true}
                    userAgent="Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Mobile Safari/537.36"
                />
            }

            {!payUrl && <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                    {amFixed.map((item, index) => (
                        <React.Fragment key={index}>
                            <TouchableOpacity key={index} onPress={() => handleAmoutClick(item)}>
                                <View style={[styles.selectedPeriodItem, { justifyContent: 'center', width: 65, height: 50, padding: 10, borderWidth: 1, margin: 5, backgroundColor: commonStyle.green, borderRadius: 5 }]}>
                                    <Text style={{ color: '#fff', textAlign: 'center' }}>{item}</Text>
                                </View>
                            </TouchableOpacity>
                        </React.Fragment>
                    ))}
                    <Text></Text>
                </View>
                <View style={{ width: '98%' }}>
                    <Text style={[styles.text, { textAlign: 'left', marginLeft: 10, fontSize: 16 }]}>{t('recharge') + t('amount')}</Text>
                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterMoney') + `(${priceMin}-${priceMax})`}
                        onChangeText={handleAmount}
                        underlineColorAndroid="transparent"
                        value={String(amount)}
                        placeholderTextColor={commonStyle.green} />
                    <Text style={styles.label}>{unit}</Text>

                </View>

                {loading ? (
                    <ActivityIndicator size="large" color={commonStyle.green} />
                ) : (
                    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
                        <Text style={styles.buttonText}>{t('confirm')}</Text>
                    </TouchableOpacity>
                )}



                <View style={{ marginTop: 20 }}>

                    <Text style={{ color: 'gray' }}>{t('tips')}</Text>
                    <Text style={{ color: 'red' }}>{t('rechargeTip.1')}{curr} = 1:{extractedNumber}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.3')}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.4')}</Text>
                </View>
            </View>

            }

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        paddingHorizontal: 15
        // height: '99%'
    },
    text: {
        color: '#fff'
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
        marginRight: 10,
        marginLeft: 5
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        marginTop: 0,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430',
        flex: 1
        // borderBottomColor: 'transparent'
    },
    button: {
        // backgroundColor: '#2C3445',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 20,
        borderRadius: 5,
        width: '90%'
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16
    },
})

export default ThirdpartyPay;