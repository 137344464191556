/** 公共配置 **/

export const commonConfig = {
    img_url: 'https://img.yilufaunres.com/',
    domain: "yilufaunres.com",
    app_down_url:"https://app.id67.com/"
}

export const commonLang = {
    acceptLanguage:{
        en: 'en-US', //英语
        cn: 'zh-CN', //中文
        es: 'es', //西班牙语
        pt: 'pt', //葡萄牙语
        vn: 'vi', //越南语
        id: 'id-ID', //印尼语
        ru: 'ru',  // 俄罗斯
        tr: 'tr',  // 土耳其
        ar: 'ar',  // 阿拉伯
        hi: 'hi',  // 印地
    },
    lang:{
        en: 'English',   //英语
        cn: '中文简体',   //中文
        es: 'Español',   //西班牙语
        pt: 'Português', //葡萄牙语
        vn: 'Tiếng Việt', //越南语
        id: 'Indonesia', //印尼语
        ru: 'Русский',  // 俄罗斯
        tr: 'Türkiye',  // 土耳其
        hi: 'हिंदी',  // 印地
        ar: 'العربية',  // 阿拉伯
    },
    languageMappings:{
        'zh-CN': 'cn',
        'zh-TW': 'cn',
        'en-US': 'en',
        'en-GB': 'en',
        'es-MX': 'es',
        'es-ES':'es',
        'es':'es',
        'pt-BR': 'pt',
        'pt-PT': 'pt',
        'vi': 'vn',
        "id-ID":"id",
        "id":"id",
    },
    faq:{
        en: [
            'How to withdraw',
            'How to deposit',
            'How to sell',
            'How to buy',
            'How to check balance',
            'How to bind bank card',
            'How to cancel order'
        ],
        cn: [
            '如何提现',
            '如何充值',
            '如何卖出',
            '如何买入',
            '如何查询余额',
            '如何绑定银行卡',
            '如何取消订单'
        ],
        es: [
            'Cómo retirar',
            'Cómo depositar',
            'Cómo vender',
            'Cómo comprar',
            'Cómo verificar el saldo',
            'Cómo vincular una tarjeta bancaria',
            'Cómo cancelar un pedido'
        ],
        pt: [
            'Como sacar',
            'Como depositar',
            'Como vender',
            'Como comprar',
            'Como verificar o saldo',
            'Como vincular cartão de banco',
            'Como cancelar pedido'
        ],
        vn: [
            'Làm thế nào để rút tiền',
            'Làm thế nào để nạp tiền',
            'Làm thế nào để bán',
            'Làm thế nào để mua',
            'Làm thế nào để kiểm tra số dư',
            'Làm thế nào để liên kết thẻ ngân hàng',
            'Làm thế nào để hủy đơn hàng'
        ],
        id : [
            'Bagaimana cara menarik uang',
            'Bagaimana cara mengisi uang',
            'Bagaimana cara menjual',
            'Bagaimana cara membeli',
            'Bagaimana cara memeriksa saldo',
            'Bagaimana cara menghubungkan kartu bank',
            'Bagaimana cara membatalkan pesanan'
          ]
    }
}