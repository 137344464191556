import { Platform } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-community/async-storage';
import { CommonActions, useNavigation } from '@react-navigation/native';

import config from '../../config'; // 在开发环境下导入开发配置
import { commonLang } from '../components/config';

import { navigationRef } from '../components/navigationRef';


// 域名列表，包括备用域名
const domains = config.apiUrl;


// 存储当前域名的索引
let currentDomainIndex = 0;

// const baseURL = 'http://local.api.com/proxy.php' //本地代理域名

const lang = commonLang.acceptLanguage;

// 存储数据
const storeData = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
    // console.log('数据已存储');
  } catch (error) {
    // console.log('存储数据时出错:', error);
  }
};


const instance = axios.create({
  // baseURL,
  timeout: 10000,
  withCredentials: true // 允许跨域请求带cookie
});


if (Platform.OS == 'web') {
  if (config.env != 'production') {
    instance.defaults.baseURL = domains[0];
  } else {
    instance.defaults.baseURL = '';
  }

} else {
  instance.defaults.baseURL = domains[0];
}




const removeItem = async () => {
  await AsyncStorage.removeItem('userinfo');
  await AsyncStorage.removeItem('cookies');
};

instance.interceptors.response.use(
  (response) => {

    const { data } = response;

    // console.log('返回', response.status,response.request.responseURL)
    // console.log('返回信息')
    // console.log(data)
    // console.log('baseurl', baseURL)
    // console.log(response)

    const cookies = response.headers['set-cookie'];
    if (cookies != undefined) {
      storeData('cookies', JSON.stringify(cookies))
    }

    // console.log('set-cookie')
    // console.log(cookies)

    if (data.status == 0) {
      const code = data.Data?.Code || data.Data1?.Code;
      // console.log('code:', code)
      switch (code) {
        case '-5':
          console.log('没有余额');
          break;
        case '-8':
          console.log('请登录');
          removeItem();
          navigationRef.current?.navigate('Login');
          break;
        case '-10':
          console.log('去维护页面');
          break;
        case '-13':
          console.log('域名未绑定平台');

          break;
        default:
          console.log('未知错误', code);
      }
    }

    return data;
  },
  (error) => {

    // console.log(error)

    return Promise.reject({ status: -1, Data: { Code: -1, msg: '发生错误：' + error.message } })

  }
);

const isNew = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (t) {
    const e = (16 * Math.random()) | 0;
    const a = t === 'x' ? e : (3 & e) | 8;
    return a.toString(16);
  });
}

instance.interceptors.request.use(
  async (config) => {
    const cookieStr = await AsyncStorage.getItem('cookies');
    // console.log('cookie请求头')
    // console.log(cookieStr)
    if (cookieStr && cookieStr.length > 0) {
      const cookies = JSON.parse(cookieStr);
      config.headers.Cookie = cookies[0];
      // console.log(config.headers.Cookie)
    }

    const language = await AsyncStorage.getItem('language');
    if (language) {
      config.headers['Accept-Language'] = lang[language];
    }

    // console.log('请求信息')

    if (Platform.OS != 'web') {
      for (const backupDomain of domains) {
        try {
          const response = await fetch(backupDomain);
          if (response.ok) {
            config.baseURL = backupDomain;
            // console.log(backupDomain, 'is accessible');
            break
          } else {
            config.baseURL = backupDomain;
            // console.log(backupDomain, 'is not accessible');
            break
          }
        } catch (error) {
          // console.log(backupDomain, 'is not accessible');
        }
      }

      config.headers['origin'] = config.baseURL;
    }

    // console.log(config)

    config.data = {
      ...config.data,
      isNew: isNew(),
    };

   
    // console.log(config)

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;