

import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput, Image, TouchableOpacity, Modal, ScrollView } from 'react-native';
import { commonStyle } from '../components/style';
import { Octicons, Feather, Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import RNPickerSelect from 'react-native-picker-select';
import SpliteLine from '../components/spliteLine';
import { FontAwesome, AntDesign } from '@expo/vector-icons';

const SecurityQuestion = () => {

    const { i18n, t } = useTranslation();

    const navigation = useNavigation();

    const [modalVisible, setModalVisible] = useState(false);

    const handleInputText1 = text => {

    };

    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleQuestion = (text) => {
        console.log(text)
        setModalVisible(false);
    };


    const questionData = [
        { label: '你的高中叫什么名字', value: 'option1' },
        { label: '谁是你的高中班主任', value: 'option2' },
        { label: '你的初中叫什么名字', value: 'option3' },
        { label: '谁是你的高中校长', value: 'option4' },
        { label: '你的小学叫什么名字', value: 'option5' },
        { label: '你知道是谁问这个问题吗', value: 'option5' },
        { label: '你的出生地址在哪里', value: 'option5' },
        { label: '你最喜欢哪种水果', value: 'option5' },
        { label: '你喜欢什么颜色', value: 'option5' },
    ];


    return (
        <View style={styles.container}>
            <View style={styles.sectionContainer}>

                <TouchableOpacity onPress={() => { openModal() }}>
                    <View style={styles.section}>

                        <View style={styles.iconText}>
                            <Text style={styles.label}>{t('第一个问题')}</Text>
                            <Text style={styles.label}>请选择问题</Text>
                            {/* <RNPickerSelect
                                items={data}
                                onValueChange={(value) => console.log(value)}
                                style={{ inputIOS: { width: 200 } }}
                            /> */}

                        </View>
                        <View style={styles.iconText}>
                            <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                        </View>

                    </View>
                </TouchableOpacity>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={styles.label}>回答</Text>
                    <TextInput
                        style={styles.input}
                        placeholder=''
                        onChangeText={handleInputText1}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                </View>



            </View>


            <View style={styles.sectionContainer}>

                <TouchableOpacity onPress={() => { openModal() }}>
                    <View style={styles.section}>

                        <View style={styles.iconText}>
                            <Text style={styles.label}>{t('第二个问题')}</Text>

                            <Text style={styles.label}>请选择问题</Text>

                        </View>
                        <View style={styles.iconText}>
                            <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                        </View>

                    </View>
                </TouchableOpacity>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={styles.label}>回答</Text>
                    <TextInput
                        style={styles.input}
                        placeholder=''
                        onChangeText={handleInputText1}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />

                </View>


            </View>


            <View style={styles.sectionContainer}>

                <TouchableOpacity onPress={() => { openModal() }}>
                    <View style={styles.section}>

                        <View style={styles.iconText}>
                            <Text style={styles.label}>{t('第三个问题')}</Text>

                            <Text style={styles.label}>请选择问题</Text>

                        </View>
                        <View style={styles.iconText}>
                            <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                        </View>

                    </View>
                </TouchableOpacity>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={styles.label}>回答</Text>
                    <TextInput
                        style={styles.input}
                        placeholder=''
                        onChangeText={handleInputText1}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />

                </View>

            </View>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>

                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >
                            <View>
                                {questionData.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleQuestion(item.value)}>
                                        <Text style={[styles.modalText]}>{item.label}</Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        // justifyContent: 'center',
        margin: 0,
    },
    sectionContainer: {
        width: '100%',
        backgroundColor: '#222430',
        marginBottom: 5,
        borderRadius: 5,
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    section: {
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    input: {
        // placeholderTextColor:'#707070',
        color: '#707070',
        marginBottom: 15,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        flex: 2,
        borderBottomColor: 'transparent',
        backgroundColor: commonStyle.bgColor,
        borderRadius: 5
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    title: {
        fontSize: 16,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    iconBadge: {
        fontSize: 16,
        // fontWeight: 'bold',
    },
    name: {
        fontSize: 20,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2
    },
    switchSection: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    country: {
        // marginRight: 2,
        color: '#828B9F'
    },
    switch: {
        // backgroundColor: '#ccc',
        // padding: 5,
        // borderRadius: 5,
        // color: '#828B9F'
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F'
    },
    icon: {
        // backgroundColor: '#ccc',
        // width: 30,
        // height: 30,
        // marginRight: 10,
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    label: {
        fontSize: 16,
        color: '#828B9F',
        marginRight: 5,
        width: 100,
        // color: commonStyle.textWhiteColor
    },
    subText: {
        fontSize: 14,
        marginRight: 10,
        color: '#828B9F'
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    iconInner: {
        backgroundColor: '#ccc',
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 30,
        height: 30
    },
    flexColumn: {
        flexDirection: 'column',
        flex: 1
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '80%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
});

export default SecurityQuestion;