import React, { PureComponent } from 'react'
import {
    View,
    StyleSheet
} from 'react-native'

class ActiveTabItem extends PureComponent {
    render() {
        let { lineHeight, color, style, contentStyle } = this.props
        return (
            <View style={{ ...contentStyle }}>
                <View style={{ height: 5, width: 30, backgroundColor: '#FAAE27', margin: 'auto', borderRadius: 5, }} />
            </View>
        )
    }
}

ActiveTabItem.defaultProps = {
    lineHeight: StyleSheet.hairlineWidth,
    color: '#FAAE27',
    contentStyle: {}
}

export default ActiveTabItem