import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Platform } from 'react-native';
import { EvilIcons,Ionicons  } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style';
import { getHuobiPrice, getKline, getSymbolPrice, getKline2All } from '../api/apis';
import { WebView } from 'react-native-webview';
import { navigateRequireLogin, virtual,isLoggedIn } from '../components/util';
import DraggableButton from '../components/draggableButton';

// https://www.coinbase.com/
const Exchange = (props) => {

    const { i18n, t } = useTranslation();
    const navigation = useNavigation();

    const timeOptionTab = { '1m': { t: '1min', s: 144 }, '5m': { t: '5min', s: 288 }, '15m': { t: '15min', s: 96 }, '30m': { t: '30min', s: 48 }, '1h': { t: '60min', s: 12 }, '4h': { t: '4hour', s: 6 }, '1d': { t: '1day', s: 1 } };
    const [activeOption, setActiveOption] = useState('1m');
    const currency = props.route ? props.route.params.currency : 'BTC'
    const [amount, setAmount] = useState('--');
    const [percentage, setPercentage] = useState('--');
    const [low, setLow] = useState('--');
    const [high, setHigh] = useState('--');
    const [vol, setVol] = useState('--');
    const [dataList2, setDataList2] = useState(false);
    const [webViewLoad, setWebViewLoad] = useState(false);

    const [headerRightButton, setHeaderRightButton] = useState('none');

    const webViewRef = useRef(null);
    const iframeRef = useRef(null);


    const handleHeaderRightClick = () => {
        if (headerRightButton == 'none') {
            setHeaderRightButton('block')
        } else {
            setHeaderRightButton('none')
        }
    };

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View>
                    <TouchableOpacity onPress={() => handleHeaderRightClick()}>
                        <EvilIcons name="navicon" size={26} color="#fff" />
                    </TouchableOpacity>
                </View>
            ),
            headerLeft: () => (
                <TouchableOpacity onPress={() =>  navigation.navigate('MainNav', { screen: 'Home' })}>
                 <Ionicons  name="arrow-back-sharp" size={24} color="#fff" />
              </TouchableOpacity>
            ),
        });
    }, [navigation, headerRightButton]);

    useEffect(() => {
        isLoggedIn(navigation)
        init()
        kline2All()
        updateDataListPrice()

        const intervalId = setInterval(init, 5000);

        // setTimeout(() => {
        //     handleTabChange('15m', '15min', 48)
        // }, 200);

        return () => {
            clearInterval(intervalId);

            if (webViewRef.current) {
                webViewRef.current = null;
            }
        };
    }, []);

    useEffect(() => {

        const intervalId2 = setInterval(updateDataListPrice, 1500);

        return () => {
            clearInterval(intervalId2);
        };

    }, [dataList2]);


    useEffect(() => {

        const intervalId2 = setInterval(updateKline, 3000);

        return () => {
            clearInterval(intervalId2);
        };

    }, [activeOption]);



    const handleBeforeRemove = (e) => {
        const actionType = e.data.action.type;
        if (actionType === 'GO_BACK') {
            navigation.navigate('MainNav', { screen: 'Home' })
        }
    };

    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', handleBeforeRemove);
        return unsubscribe;
    }, [navigation]);

    useFocusEffect(
        React.useCallback(() => {
            init()
            setTimeout(() => {
                const tabData = timeOptionTab[activeOption]
                handleTabChange(activeOption, tabData.t, tabData.s)
            }, 3000);
        }, [])
    );

    const init = async () => {
        
        // console.log('init')
        const symbolPrice = await getSymbolPrice(currency);
        if (symbolPrice) {
            setAmount(symbolPrice)
        } else {
            const huobiPrice = await getHuobiPrice(currency);
            if (huobiPrice) {
                setAmount(huobiPrice)
            }
        }
        const kline = await getKline(currency, '1day', 2);
        if (kline) {
            // console.log('kline')
            setPercentage(kline.changePercentage)
            setLow(kline.klineData[0].low)
            setHigh(kline.klineData[0].high)
            setVol(kline.klineData[0].vol.toFixed(2))
        }
    }

    const updateKline = async () => {
        const tabData = timeOptionTab[activeOption]
        // console.log('activeOption',activeOption)
        // console.log(tabData)
        handleTabChange(activeOption, tabData.t, tabData.s,false)
    }

    const kline2All = async () => {
        const kline2All = await getKline2All();
        if (kline2All) {
            setDataList2(kline2All);
        }
    };

    const updateDataListPrice = async () => {
        if (dataList2) {
            const currencyData = dataList2[currency];
            if (currencyData && currencyData.length > 0) {
                const randomIndex = Math.floor(Math.random() * currencyData.length);
                const selectedData = currencyData[randomIndex];
                setAmount(selectedData.price)
            }
        }
    };


    const goToConfirmOrderScreen = (direction) => {
        // navigation.navigate('ConfirmOrder', { currency: currency, upDown: direction });

        const route = {
            name: 'ConfirmOrder', // 路由地址
            params: { currency: currency, upDown: direction },
        };
        navigateRequireLogin(navigation, route);

    };

    const handleTabChange = (key, value, size,isButtonClick=true) => {
        console.log(key, value, size)

        if(isButtonClick){
            setActiveOption(key);
        }
      
        setTimeout(() => {
            if (webViewRef.current && Platform.OS != 'web') {
                webViewRef.current.injectJavaScript(`tabChange("${value}","${size}");`);
            }

            if (Platform.OS == 'web') {
                // console.log('触发iframe方法')
                const iframe = iframeRef.current;
                iframe.contentWindow.postMessage({ type: "callFunction", data: value }, "*");
            }

        }, 100);

    };

    const goToExchangeScreen = (currency) => {
        setHeaderRightButton('none')
        navigation.replace('Exchange', { currency: currency });
    }

    const htmlContent = `
    <html>
    <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
        <script src="https://cdn.bootcdn.net/ajax/libs/jquery/3.7.1/jquery.js"></script>
        <script src="https://cdn.bootcss.com/echarts/3.7.1/echarts.min.js"></script>
        <script src="https://cdn.staticfile.org/echarts/5.2.0/theme/macarons.min.js"></script>
        <style>
        body {
            background-color: #121420;
            margin:0px;
            padding:0px;
            height:700px;
        }
        #chart{
           
        }
        .loading {
            display: none; 
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        .loading .spinner {
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-top: 4px solid #13B777;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    </style>
    </head>
    <body>
        <div class="tab">
            <div class="currency" style="display:none">${currency.toLowerCase().trim()}usdt</div>
            <div class="loading">
                <div class="spinner"></div>
            </div>
            <div id="chart" style="width:100%;height:380px;">11</div>
        </div>
        <script>

            let defaultData = [];

            var chartDom = document.getElementById('chart');
            var myChart = echarts.init(chartDom, 'macarons');
            
            myChart.resize();

            // 设置主题背景色
            myChart.setOption({
                backgroundColor: '#121420',
                textStyle: {
                    color: '#fff', // 文本颜色
                },
                // 其他配置项...
                series: [
                    // 图表系列项的配置...
                    
                ]
            });
            
            var option;
    
   
            // 初始化图表
            function initChart(data,isFirst = false) {

                defaultData = [];

                const dates = data.map(function (item) {
                    return item[0];
                });
                const chartData = data.map(function (item) {
                    return [+item[1], +item[2], +item[5], +item[6]];
                });
                option = {
                    width: '86%',
                    legend: {
                        data: [
                            { name: '日K', textStyle: { color: '#fff' } },
                            { name: 'MA5', textStyle: { color: 'fff' } }, // 设置 MA5 的文字颜色为蓝色
                            { name: 'MA10', textStyle: { color: '#fff' } }, // 设置 MA10 的文字颜色为绿色
                            { name: 'MA20', textStyle: { color: '#fff' } }, // 设置 MA20 的文字颜色为橙色
                            { name: 'MA30', textStyle: { color: '#fff' } } // 设置 MA30 的文字颜色为紫色
                        ],
                        inactiveColor: '#777'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            animation: false,
                            type: 'cross',
                            lineStyle: {
                                color: '#376df4',
                                width: 2,
                                opacity: 1
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: dates,
                        axisLine: { lineStyle: { color: '#8392A5' } }
                    },
                    yAxis: {
                        axisLabel: {
                            rotate: 45,
                        },
                        scale: true,
                        axisLine: { lineStyle: { color: '#8392A5' } },
                    },
                    grid: {
                        bottom: 80
                    },
                    dataZoom: [
                        {
                            right: '5%',
                            textStyle: {
                                color: '#8392A5'
                            },
                            handleIcon:
                                'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c-4.9,0.3-8.8,4.4-8.8,9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                            dataBackground: {
                                areaStyle: {
                                    color: '#8392A5'
                                },
                                lineStyle: {
                                    opacity: 0.8,
                                    color: '#8392A5'
                                }
                            },
                            brushSelect: true
                        },
                        {
                            type: 'inside'
                        }
                    ],
                    series: [
                        {
                            type: 'candlestick',
                            name: 'Day',
                            data: chartData,
                            itemStyle: {
                                normal: {
                                    color: '#00FF00',  // 涨的颜色，这里设置为绿色
                                    color0: '#FF0000',  // 跌的颜色，这里设置为红色
                                    borderColor: '#00FF00',
                                    borderColor0: '#FF0000'
                                }
                            }
                        },
                        {
                            name: 'MA5',
                            type: 'line',
                            data: calculateMA(5, chartData),
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: 'MA10',
                            type: 'line',
                            data: calculateMA(10, chartData),
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: 'MA20',
                            type: 'line',
                            data: calculateMA(20, chartData),
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 1
                            },
                            
                        },
                        {
                            name: 'MA30',
                            type: 'line',
                            data: calculateMA(30, chartData),
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 1
                            }
                        }
                    ]
                };

                if (isFirst) {
                    // option.dataZoom[0].start = 70;
                }

                option && myChart.setOption(option);
            }
    
            // 计算均线
            function calculateMA(dayCount, data) {
                var result = [];
                for (var i = 0, len = data.length; i < len; i++) {
                    if (i < dayCount) {
                        result.push('-');
                        continue;
                    }
                    var sum = 0;
                    for (var j = 0; j < dayCount; j++) {
                        sum += +data[i - j][1];
                    }

                    var v = (sum / dayCount).toFixed(2);
                    result.push(v);
                }
                return result;
            }
    
            // 初始化图表
            initChart([],true);

            setTimeout(() => {
                tabChange('15min', 48)
            }, 1000);


            function myFunction(data) {
                //alert("方法被调用了！参数是："+data);
                tabChange(data,200)
            }
        
            window.addEventListener("message", function (event) {
                if (event.data.type === "callFunction") {
                  myFunction(event.data.data);
                }
            });
    
            // 更新图表数据
            function tabChange(tab,size) {
                
                const symbol = $('.currency').text()

                let newKlineData = []
                
                // 显示加载动画
                // $('.loading').fadeIn();
                // $('#chart').hide()

                $.ajax({
                    url: 'https://api-aws.huobi.pro/market/history/kline',
                    type: 'GET',
                    data: {
                        symbol: symbol,
                        period: tab,
                        size: 40
                    },
                    success: function (data) {
                        // 加载完成后隐藏加载动画
                        // $('.loading').fadeOut();
                        // $('#chart').show()
                        // 请求成功 data 包含响应数据
                        if (data.status == 'ok') {
                            // console.log(data.data)
                            const res = data.data
                            for (i = 0; i < res.length; i++) {
            
                                const timestamp = res[i].id * 1000; // 转换为毫秒级时间戳
                                const date = new Date(timestamp);
                                const year = date.getFullYear();
                                const month = date.getMonth() + 1;
                                const day = date.getDate();
                                const hours = date.getHours();
                                const minutes = date.getMinutes();
                                const seconds = date.getSeconds();

                                const formattedTime =   String(month).padStart(2, '0') + '/' + String(day).padStart(2, '0') + '-' + String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') ;

                                newKlineData.push([formattedTime, res[i].open, res[i].close, '-', '-', res[i].low, res[i].high, res[i].vol, res[i].count,'-'])
                                
                            }
                            initChart(newKlineData.reverse());
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // $('.loading').fadeOut();
                        // $('#chart').show()
                        console.log('Error: ' + textStatus);
                    }
                });

            }
        </script>
    </body>
    </html>
  `;

    const BottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>
                <TouchableOpacity style={styles.button} onPress={() => goToConfirmOrderScreen('up')}>
                    <View style={styles.buttonLeft}>
                        <Text style={styles.bottomText}>{t('buyUp')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button]} onPress={() => goToConfirmOrderScreen('down')}>
                    <View style={styles.buttonRight}>
                        <Text style={styles.bottomText}>{t('buyDown')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <>
            <View style={styles.container}>
                <DraggableButton />
                <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 140, position: 'absolute', top: 0, right: 15, zIndex: 999 }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {Object.entries(virtual()).map(([symbol, name]) => (
                            <View key={name}>
                                <TouchableOpacity onPress={() => goToExchangeScreen(symbol)}>
                                    <View style={{ width: 68 }}>
                                        <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                </View>

                <Text style={styles.currencyText}>{currency}</Text>
                <View style={styles.infoContainer}>
                    <View style={{ flex: 1, justifyContent: 'flex-start', marginLeft: 10 }}>
                        <Text style={[styles.infoText, { color: percentage > 0 ? '#0ECB81' : '#F5465C' }]}>{amount}</Text>
                        <Text style={[styles.infoSubtext, { color: percentage > 0 ? '#0ECB81' : '#F5465C' }]}>{percentage}{percentage != '--' && '%'}</Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <InfoRow label={t('low')} value={low} />
                        <InfoRow label={t('high')} value={high} />
                        <InfoRow label={'24h' + t('vol')} value={vol} />
                    </View>
                </View>
                <View style={styles.timeContainer}>
                    <View><Text style={styles.timeText}>{t('time')}</Text></View>
                    {Object.keys(timeOptionTab).map((key) => (
                        <TouchableOpacity key={key} onPress={() => handleTabChange(key, timeOptionTab[key].t, timeOptionTab[key].s)}>
                            <View>
                                <Text style={styles.timeText}>{key} </Text>
                                {key == activeOption && <ActiveTabItem />}
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
                <View style={styles.chartContainer}>

                    {Platform.OS == 'web' && <iframe srcDoc={htmlContent} ref={iframeRef} width="100%" height="100%" style={{ border: '0px' }} />}
                    {Platform.OS != 'web' && <WebView
                        style={{ flex: 1, backgroundColor: '#000' }}
                        ref={webViewRef}
                        onMessage={(event) => {
                            console.log('event.nativeEvent')
                            console.log(event.nativeEvent);
                        }}
                        source={{ html: htmlContent }}
                        // onLoadEnd={() => setWebViewLoad(true)}
                        onLoad={() => {
                            setWebViewLoad(true)
                        }}
                    />}


                </View>
                <BottomButtons />
            </View>
        </>
    );
};

const InfoRow = ({ label, value }) => (
    <View style={styles.infoRow}>
        <Text style={styles.infoLabel}>{label}</Text>
        <Text style={styles.infoValue}>{value}</Text>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        // alignItems: 'center',
    },
    currencyText: {
        color: commonStyle.textWhiteColor,
        fontSize: 16,
        marginTop: 5,
        textAlign: 'center'
    },
    infoContainer: {
        width: '90%',
        marginTop: 5,
        // paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoColumn: {
        flex: 1,
        flexDirection: 'column',
    },
    infoText: {
        fontSize: 20,
        color: '#2CF793',
    },
    infoSubtext: {
        fontSize: 15,
        color: '#2CF793',
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoLabel: {
        color: '#828B9F',
        marginRight: 10
    },
    infoValue: {
        color: '#fff',
    },
    timeContainer: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        paddingBottom: 10,
        marginLeft: 10
    },
    timeText: {
        color: '#fff',
        textAlign: 'center'
    },
    chartContainer: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // width: '90%',
    },
    chart: {
        height: 200,
        width: '100%',
        padding: 20,
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 10,
        flexDirection: 'row',
        width: '100%',
        backgroundColor: commonStyle.bgColor,
    },
    button: {
        flex: 1,
        paddingVertical: 15,
        alignItems: 'center',
    },
    buttonWrapper: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width: '80%',
        justifyContent: 'center',
        borderRadius: 10,
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width: '80%',
        justifyContent: "center",
        // paddingHorizontal: 60,
        borderRadius: 10
    },
    buttonRight: {
        backgroundColor: '#F5465C',
        width: '80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5
    },
});

export default Exchange;