import React, { useEffect, useRef } from 'react';
import { WebView } from 'react-native-webview';
import { StyleSheet, View, Text, TouchableOpacity, Clipboard } from 'react-native';
import { commonStyle } from '../components/style';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

const Pay = () => {
  const navigation = useNavigation();
  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: '',
      // title: t('purchase') + 'BTC',
      headerStyle: {
        backgroundColor: commonStyle.bgColor,
        borderBottomWidth: 0,
        elevation: 0,
        shadowOpacity: 0,
      },
      headerTitleStyle: {

      },
    });
  }, [navigation]);

  const { i18n, t } = useTranslation();

  const handleCopy = () => {
    Clipboard.setString();
  }

  const handlePay = (value) => {

  }

  const handleCustomer = () => {

  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.headingContainer}>
          <View></View>
          <View><Text style={styles.headingText}>{t('purchase')}BTC</Text></View>
          <TouchableOpacity onPress={handleCustomer}>
            <AntDesign name="customerservice" size={16} color="#FFF" />
          </TouchableOpacity>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.contentRow}>
            <View>
              <Text style={styles.contentTitle}>{t('pleaseMakePayment')}</Text>
              <Text style={styles.contentText}>{t('completeTheTransactionBeforeTheCountdownends')}</Text>
            </View>
            <View>
              <Text style={styles.amountText}>28:13</Text>
              <Text style={styles.contentText}>{t('paymentCountdown')}</Text>
            </View>
          </View>
          <View style={styles.paymentDetailsContainer}>
            <View style={styles.paymentDetailRow}>
              <Text style={styles.paymentDetailTitle}>{t('收款人')}</Text>
              <View style={styles.paymentDetailTextContainer}>
                <Text style={styles.paymentDetailText}>王小车</Text>
                <TouchableOpacity style={styles.copyIconContainer} onPress={handleCopy}>
                  <AntDesign name="copy1" size={15} color="#828B9F" />
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.paymentDetailRow}>
              <Text style={styles.paymentDetailTitle}>{t('账号')}</Text>
              <View style={styles.paymentDetailTextContainer}>
                <Text style={styles.paymentDetailText}>1434135415314</Text>
                <TouchableOpacity style={styles.copyIconContainer} onPress={handleCopy}>
                  <AntDesign name="copy1" size={15} color="#828B9F" />
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.paymentDetailDivider} />

            <View style={styles.paymentDetailRow}>
              <Text style={styles.paymentDetailTitle}>{t('订单编号')}</Text>
              <View style={styles.paymentDetailTextContainer}>
                <Text style={styles.paymentDetailText}>123456</Text>
              </View>
            </View>

            <View style={styles.paymentDetailRow}>
              <Text style={styles.paymentDetailTitle}>{t('单价')}</Text>
              <View style={styles.paymentDetailTextContainer}>
                <Text style={styles.paymentDetailText}>123456</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <TouchableOpacity style={styles.button} onPress={() => handlePay('close')}>
            <View style={styles.buttonLeft}>
              <Text style={styles.bottomText}>{t('cancelOrder')}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => handlePay('pay')}>
            <View style={styles.buttonRight}>
              <Text style={styles.bottomText}>{t('payNow')}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: commonStyle.bgColor,
  },
  headingContainer: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop:10
  },
  headingText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
  contentContainer: {
    width: '95%',
    justifyContent: 'center',
    paddingHorizontal: 5,
    marginTop: 20,
  },
  contentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentTitle: {
    color: '#fff',
    fontSize: 18,
  },
  contentText: {
    color: '#fff',
    marginTop: 10,
  },
  amountText: {
    color: '#F5465C',
    fontSize: 20,
    textAlign: 'right',
  },
  paymentDetailsContainer: {
    backgroundColor: '#2C3445',
    borderRadius: 5,
    marginTop: 15,
    padding: 15,
  },
  paymentDetailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
  paymentDetailTitle: {
    color: '#fff',
  },
  paymentDetailTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentDetailText: {
    color: '#fff',
    marginRight: 10,
  },
  copyIconContainer: {
    paddingHorizontal: 5,
  },
  paymentDetailDivider: {
    paddingHorizontal: 20,
    height: 1,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#707070',
    marginVertical: 20,
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    flexDirection: 'row',
    width: '100%',
  },
  button: {
    flex: 1,
    paddingVertical: 15,
    alignItems: 'center',
  },
  buttonLeft: {
    backgroundColor: '#F5465C',
    paddingVertical: 10,
    width: '80%',
    justifyContent: 'center',
    borderRadius: 10,
  },
  buttonRight: {
    backgroundColor: commonStyle.green,
    width: '80%',
    paddingVertical: 10,
    borderRadius: 5,
  },
  bottomText: {
    textAlign: 'center',
    color: commonStyle.textWhiteColor,
  },
});

export default Pay;