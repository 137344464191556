import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Image, Button, Alert, Clipboard } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
import { amountUnit, unitconversion } from '../components/util'
import { getWebsiteInfo, getRechargetest, rechargeChannel, updateorder } from '../api/apis';
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

const Payment = (props) => {

    const { t, i18n } = useTranslation();
    const toastRef = useRef(null);
    const navigation = useNavigation();

    const [amount, setAmount] = useState('');
    const [amountExchangerate, setAmountExchangerate] = useState(0);

    const [unit, setUnit] = useState('');
    const [curr, setCurr] = useState('');
    const [exchangerate, setExchangerate] = useState('');
    const [extractedNumber, setExtractedNumber] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [amFixed, setAmFixed] = useState([]);
    const [thirdName, setThirdName] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [payee, setPayee] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [payUrl, setPayUrl] = useState('');
    const [orderSn, setOrderSn] = useState('');


    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.payItem.title,
        });
    }, [navigation]);

    useEffect(() => {

        const payItem = props.route.params.payItem

        setPriceMin(payItem.price_min)
        setPriceMax(payItem.price_max)

        setThirdName(payItem.third_name)
        setAmFixed(payItem.am_fixed)

        init()
    }, []);



    const init = async () => {
        console.log('page:Payment')
        
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            // console.log(curr, i18n.language)
            // setCurr(curr)
            // setCurr(props.route.params.curr)

            // alert(curr)
            // alert(props.route.params.curr + response.Data.exchangerate)

            setExchangerate(response.Data.exchangerate) // "USDT:1;MXN:19.01"

            const splitCurr = response.Data.exchangerate.split(";");
            // console.log(splitCurr)


            for (var i = 0; i < splitCurr.length; i++) {
                let itemSplit = splitCurr[i].split(":")
                if (itemSplit[0] == props.route.params.curr) {
                    // alert(itemSplit[0] + itemSplit[1])
                    setCurr(itemSplit[0])

                    if (itemSplit[0] == "USDT") {
                        setPayee("TRC(20)")

                    } else if (itemSplit[0] == "TRX") {
                        setPayee("TRX")
                    }

                    setExtractedNumber(itemSplit[1])
                    break
                }
            }



            // var parts = response.Data.exchangerate.split(":");
            // var extractedNumber = parts[1];
            // setExtractedNumber(extractedNumber)  


            const au = amountUnit(curr, i18n.language)
            // console.log(au.unit)
            // au.icon
            setUnit(au.unit)
        }
    }

    const handleAmoutClick = (value) => {
        setAmount(value)
    }

    const handleAmount = (value) => {
        setAmount(value)
    }

    const handleCopy = (text) => {
        Clipboard.setString(text);
        toastRef.current.show(t('success'));
    }

    const handleSubmit = async () => {

        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount.toString())) {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }

        if (Number(amount) >= Number(priceMin) && Number(amount) <= Number(priceMax)) {
            setAmountExchangerate(Number(extractedNumber) * Number(amount))

            const payType = props.route.params.payType

            let id = 0;
            let nickName = '';

            if (payType == 'RechargeWayTron') {
                id = 1
                nickName = 'tron'
            }

            const params = {
                data: `{"Money":"${amount}","Exchangerate":"${curr}:${extractedNumber}","NickName":"${nickName}","id":"${id}"}`,
                operating_state: 5
            }

            console.log(params)
            const response = await rechargeChannel(params)
            console.log(response)
            if (response.status == '1') {
                if(response.item.operating == 0){
                    showAlert(response.Data.msg)
                    return 
                }
                setIsConfirm(true)
                setOrderSn(response.item.order_sn)
                setPayUrl(response.item.pay_url) //https://pay.jogoeletronicos.com/pay/unpaid//unpaid/MjAyMzExMjUxNTU1NDExNTA=
                setWalletAddress(response.item.wallet_address)
            }

            // if (response.status == '0') {
            //     showAlert(response.Data.msg)
            // }
        } else {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }
    }


    const payUpdateorder = async () => {

        navigation.goBack()

        // const response = await updateorder(orderSn)
        // console.log(response)
        // if (response.data.code == "501") {
        //     showAlert(t('success'))
        //     navigation.goBack()
        // }
    }


    return (
        <View style={styles.container}>

            {isConfirm && <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('pay.9')}：{payee}</Text>
                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('pay.10')}：{walletAddress}</Text>

                    <TouchableOpacity style={{marginTop:15}} onPress={() => handleCopy(walletAddress)}>
                        <AntDesign name="copy1" size={20} color="#828B9F" />
                    </TouchableOpacity>

                </View>

                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('agentReport.6')}:{amount}</Text>
                </View>

                <TouchableOpacity style={[styles.button, { width: '90%' }]} onPress={() => payUpdateorder()}>
                    <Text style={styles.buttonText}>{t('theTransferHasBeenCompleted')}</Text>
                </TouchableOpacity>

            </View>}

            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>


                {!isConfirm && <View>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                        {amFixed.map((item, index) => (
                            <React.Fragment key={index}>
                                <TouchableOpacity key={index} onPress={() => handleAmoutClick(item)}>
                                    <View style={[styles.selectedPeriodItem, { justifyContent: 'center', width: 65, height: 50, padding: 10, borderWidth: 1, margin: 5, backgroundColor: commonStyle.green, borderRadius: 5 }]}>
                                        <Text style={{ color: '#fff', textAlign: 'center' }}>{item}</Text>
                                    </View>
                                </TouchableOpacity>
                            </React.Fragment>
                        ))}
                        <Text></Text>
                    </View>
                    <View style={{ width: '98%' }}>
                        <Text style={[styles.text, { textAlign: 'left', marginLeft: 10, fontSize: 16 }]}>{t('recharge') + t('amount')}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                        <TextInput
                            style={styles.input}
                            placeholder={t('pleaseEnterMoney') + `(${priceMin}-${priceMax})`}
                            onChangeText={handleAmount}
                            underlineColorAndroid="transparent"
                            value={String(amount)}
                            placeholderTextColor={commonStyle.green} />
                        <Text style={styles.label}>{unit}</Text>
                    </View>

                    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
                        <Text style={styles.buttonText}>{t('confirm')}</Text>
                    </TouchableOpacity>

                </View>
                }

                <View style={{ marginTop: 20 }}>
                    <Text style={{ color: 'gray' }}>{t('tips')}</Text>
                    <Text style={{ color: 'red' }}>{t('rechargeTip.1')}{curr} = 1:{extractedNumber}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.3')}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.4')}</Text>
                </View>

            </View>



            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        paddingHorizontal: 15
        // height: '99%'
    },
    text: {
        color: '#fff'
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
        marginRight: 10,
        marginLeft: 5
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        marginTop: 0,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430',
        flex: 1
        // borderBottomColor: 'transparent'
    },
    button: {
        // backgroundColor: '#2C3445',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 20,
        borderRadius: 5,
        // width: '90%'
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16
    },
})

export default Payment;