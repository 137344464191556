import React, { useRef, useEffect, useState } from 'react';
import { View, Image, TouchableOpacity, StyleSheet, PanResponder, Animated, Linking, Platform } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { getWebsiteInfo } from '../api/apis';
import { commonStyle } from './style';

const DraggableButton = () => {
    const navigation = useNavigation();

    const [kefu, setKefu] = useState(false);

    useEffect(() => {
        if (Platform.OS == 'web') {
            init()
        }
    }, []);


    const init = async () => {
        // const response = await getWebsiteInfo();
        // if (response.status == '1') {
        //     // setKefu(response.Data.sl)
        //     setKefu('https://t.me/example_use')
        // }
    }


    const goToCustomerScreen = async () => {

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            // response.Data.sl = 'https://t.me/example_use'
            var regex = /^https?:\/\/t\.me\/[a-zA-Z0-9_]+$/;
            if(regex.test(response.Data.sl)){
                if (Platform.OS == 'web') {
                    window.location.href = response.Data.sl
                }else{
                    Linking.openURL(response.Data.sl)
                }
            }else{
                navigation.navigate('Customer');
            }
        }


        // if (Platform.OS == 'web') {
        //     if(kefu){
        //         Linking.openURL(kefu)
        //     }

        //    return 
        // }

        // navigation.navigate('Customer');
    }

    const pan = useRef(new Animated.ValueXY()).current;

    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: Animated.event(
                [
                    null,
                    { dx: pan.x, dy: pan.y }
                ],
                { useNativeDriver: false }
            ),
            onPanResponderRelease: () => {
                pan.flattenOffset();
            }
        })
    ).current;


    return (
        <Animated.View
            style={[
                styles.container,
                {
                    transform: [{ translateX: pan.x }, { translateY: pan.y }],
                },
            ]}
            {...panResponder.panHandlers}
        >

            {Platform.OS == 'web1' && kefu && <div style={styles.button}><a href={kefu} target='_blank'> <Image style={styles.image} source={require('../../assets/imgs/tabs/customer_select.png')} /></a></div>}
            <TouchableOpacity style={styles.button} onPress={goToCustomerScreen}>
                <Image style={styles.image} source={require('../../assets/imgs/tabs/customer_select.png')} />
            </TouchableOpacity>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        right: 15,
        bottom: 100,
        zIndex: 1,
    },
    button: {
        width: 40,
        height: 40,
        // backgroundColor: commonStyle.bgColor,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 25,
    },
    image: {
        width: 38,
        height: 38,
    },
});

export default DraggableButton;