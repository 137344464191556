import React, { PureComponent } from 'react'
import {
  View,
  StyleSheet
} from 'react-native'

class SpliteLine extends PureComponent {
  render() {
    let { lineHeight, color, style, contentStyle } = this.props
    return (
      <View style={{ ...contentStyle }}>
        <View style={[{ opacity: 0.2, height: 0, borderTopWidth: lineHeight, borderColor: color, margin: StyleSheet.hairlineWidth }, style]} />
      </View>
    )
  }
}

SpliteLine.defaultProps = {
  lineHeight: StyleSheet.hairlineWidth,
  color: '#707070',
  contentStyle: {}
}

export default SpliteLine