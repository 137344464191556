import { Platform, Alert } from 'react-native';

// showAlert('messaage'), () => {
//   console.log('')
// },'ok','cancel');

export function showAlert(message, onConfirm, ok = 'ok',cancel = '') {
  if (Platform.OS === 'web') {
    if(cancel == ''){
      window.alert(message)
    }else{
      window.confirm(message)
    }

    if (typeof onConfirm === 'function') {
      onConfirm();
    }
  } else {
    
    const buttons = [
      { text: cancel, style: 'cancel' },
    ];

    if (typeof onConfirm === 'function') {
      buttons.push({ text: ok, onPress: onConfirm });
    }

    Alert.alert('', message, buttons, { cancelable: false });
  }
}
