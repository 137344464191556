import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, Animated, TouchableOpacity, Image, ImageBackground, Easing } from 'react-native';

const WheelOfFortune = () => {
  const rotationValue = useRef(new Animated.Value(0)).current;
  const [clickCount, setClickCount] = useState(5);
  const [rotationEnabled, setRotationEnabled] = useState(false);
  const [resetAngle, setResetAngle] = useState(0);
  const [startAngle, setStartAngle] = useState(0);

  const [textRotation, setTextRotation] = useState(0);

  const rotateInterpolate = rotationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [`${startAngle}deg`, `${startAngle + resetAngle}deg`],
  });

  const animatedStyle = {
    transform: [{ rotate: rotateInterpolate }],
  };


  const startRotation = () => {
    if (rotationEnabled) {
      // alert('禁止')
      return
    }

    if (clickCount <= 0) {
      alert('抽奖次数已用完')
      return;
    }


    const animationConfig = {
      toValue: 4,
      duration: 10000,
      easing: Easing.out(Easing.exp),
      useNativeDriver: true,
    };

    setRotationEnabled(true);
    Animated.timing(rotationValue, animationConfig).start(({ finished }) => {
      if (finished) {
        setClickCount(prevClickCount => prevClickCount - 1);
        setRotationEnabled(false);
      }
    });
  };

  const resetRotation = () => {
    // setRotationEnabled(false);
    setStartAngle(value => 0); // 设置起始角度
    setResetAngle(value => 360); // 设置结束角度
    // setStartAngle(Math.random() * 360); // 设置随机的起始角度

    if (!rotationEnabled) {
      rotationValue.setValue(0);
      startRotation();
    }

  };

  // const resetClickCount = () => {
  //   setClickCount(5);
  // };


  // useEffect(() => {
  //   const listener = rotationValue.addListener(({ value }) => {
  //     // 角度发生变化时的操作
  //     // const angle = (value % 1) * 360; // 将值转换为0到度的范围
  //     const angle = Math.round((value % 1) * 360 * 100) / 100; // 将值转换为0到360度的范围，并
  //     console.log('当前角度:', angle);
  //     setTextRotation(angle)
  //   });

  //   return () => {
  //     rotationValue.removeListener(listener);
  //   };
  // }, [rotationValue]);


  const rewards = ['奖品1', '奖品2', '奖品3', '奖品4', '奖品5', '奖品6', '奖品7', '奖品8']; // 奖品列表


  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('../../assets/imgs/luckyWheel/luckyWheel_bg.jpg')}
        style={styles.backgroundImage}
      >
        <View style={{ marginVertical: 50 }}>
          <Text style={styles.title}>幸运抽奖</Text>
          <Text style={styles.cjcs}>剩余抽奖次数：{clickCount}</Text>
        </View>

        {/* <TouchableOpacity onPress={() => resetRotation()}>
          <View>
            <Text style={styles.cjcs}>重置角度</Text>
          </View>
        </TouchableOpacity> */}
{/*         
        <TouchableOpacity onPress={resetClickCount}>
          <View style={{ marginTop: 10 }}>
            <Text style={styles.cjcs}>重置抽奖次数</Text>
          </View>
        </TouchableOpacity> */}

        <View style={styles.box}>
          {/* <Animated.Image
            source={require('../../assets/imgs/luckyWheel/img_bg.png')}
            style={[styles.centerImage, animatedStyle]}
          /> */}

          <Animated.View style={[styles.wheelContainer, animatedStyle]}>
            <Image source={require('../../assets/imgs/luckyWheel/img_bg.png')} style={styles.wheelContainer} />
            <View style={styles.rewardContainer}>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `70deg` },{ translateX: -105 },{ translateY: -40 },],}]}>一等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `30deg` },{ translateX: -55 },{ translateY: -128 },],}]}>八等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-28deg` },{ translateX: 110 },{ translateY: -168 },],}]}>七等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-65deg` },{ translateX: 205 },{ translateY: -82 },],}]}>六等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-109deg` },{ translateX: 235 },{ translateY: 75 },],}]}>五等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-150deg` },{ translateX: 145 },{ translateY: 222},],}]}>四等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-198deg` },{ translateX: -55 },{ translateY: 260},],}]}>三等奖</Animated.Text>
              <Animated.Text style={[styles.rewardText, {transform: [{ rotate: `-250deg` },{ translateX: -246 },{ translateY: 92},],}]}>二等奖</Animated.Text>
            </View>

          </Animated.View>

          {/* <Text onPress={resetRotation} style={{ top: 90, fontSize: 18, color: '#fff', zIndex: 999, position: 'absolute', marginTop: -20 }}>开始</Text> */}

          <TouchableOpacity onPress={resetRotation}>
            <Image
              source={require('../../assets/imgs/luckyWheel/img_pointer.png')}
              style={styles.overlayImage}
            />
          </TouchableOpacity>

        </View>
      </ImageBackground>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    position: 'absolute',
  },
  wheelContainer: {
    width: 250,
    height: 250,
  },
  centerImage: {
    width: 250,
    height: 250,
    resizeMode: 'contain',
    marginTop: 100,
  },
  overlayImage: {
    width: 100,
    height: 100,
    position: 'relative',
    top: -190,
    resizeMode: 'contain',
  },
  title: {
    fontSize: 35,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    zIndex: 999,
  },
  cjcs: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    zIndex: 999,
  },
  box: {
    // borderWidth: 1,
    // borderColor: 'red',
    height: 270,
    width: 270,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    transform: [{ translateY: 50 }]
    // marginTop: 50,
  },
  rewardText: {
    fontSize: 16,
    // fontWeight: 'bold',
    // marginBottom: 10,
    color: '#fff',
    // transform: [
    //   { rotate: `10deg` },
    //   { translateX: 50 },
    //   { translateY: -180 },
    // ],
  },
  rewardContainer: {

  }
});

export default WheelOfFortune;