import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, Animated, TouchableOpacity, Image, ImageBackground, Easing, Modal } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { getdraw } from '../api/apis';

const WheelOfFortune = () => {

    const navigation = useNavigation();
    const { i18n, t } = useTranslation();

    const rotationValue = useRef(new Animated.Value(0)).current;
    const [clickCount, setClickCount] = useState(0);
    const [rotationEnabled, setRotationEnabled] = useState(false);
    const [resetAngle, setResetAngle] = useState(0);
    const [startAngle, setStartAngle] = useState(0);
    const [bonus, setbonus] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View>
                    <TouchableOpacity onPress={gotorecord}>
                        <Text style={{ color: '#fff', marginRight: 10, fontSize: 16 }}>{t('record')}</Text>
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [navigation]);

    useEffect(() => {

        init()

    }, []);
    const gotorecord = () => {
        navigation.navigate('BillingRecord');
    };
    const init = async () => {
        const response = await getdraw('get');
        if (response.status == '1') {
            console.log(response.Data.item.total+"-"+response.Data.item.remaining);
            setClickCount((+response.Data.item.total) - (+response.Data.item.remaining));
        }
        // const response2 = await getdraw('default');
        // if (response2.status == '1') {
        //     console.log( response2.Data )
        // }

        // const response3 = await getdraw('receive');
        // if (response3.status == '1') {
        //     console.log( response3.Data )
        // }

    }


    const rotateInterpolate = rotationValue.interpolate({
        inputRange: [0, 1],
        outputRange: [`${startAngle}deg`, `${startAngle + resetAngle}deg`],
    });

    const animatedStyle = {
        transform: [{ rotate: rotateInterpolate }],
    };

    const putreceive = async () => {
        if (clickCount <= 0) {
            alert(t('Thenumberofdrawshasbeenexhausted'))
            return;
        }
        const response = await getdraw('receive');
        if (response.status === '1') {
            console.log(response.Data);
            if(response.Data.bonus !== undefined){
                // 确保转盘从0度开始转动
                setStartAngle(0);
                // 根据ranking计算结束角度，每个ranking值代表的角度是45度
                // const angle = (+response.Data.ranking - 1) * 45 + 383; // -1是因为假设ranking从1开始，但我们的计算是从0度开始
                const angle =  729- (+response.Data.ranking - 1) * 12;
                resetRotation(angle);
                setbonus(response.Data.bonus);
            }
        }
    }
    
    const startRotation = async () => {
        if (rotationEnabled) {
            // alert('禁止')
            return
        }

        if (clickCount <= 0) {
            alert(t('Thenumberofdrawshasbeenexhausted'))
            return;
        }


        const animationConfig = {
            toValue: 4,
            duration: 10000,
            easing: Easing.out(Easing.exp),
            useNativeDriver: true,
        };

        setRotationEnabled(true);
        Animated.timing(rotationValue, animationConfig).start(({ finished }) => {
            if (finished) {
                setClickCount(prevClickCount => prevClickCount - 1);
                setRotationEnabled(false);
                openPopup()
            }
        });
        init;
    };
    const resetRotation = (angle) => {
        console.log("旋转角度", angle);
        // 设置结束角度
        setResetAngle(angle);
        // 如果当前没有在转动，则开始转动
        if (!rotationEnabled) {
            rotationValue.setValue(0); // 重置动画值为0
            startRotation(); // 开始转动
        }
    };

    const openPopup = () => {
        setIsVisible(true);
    };

    const closePopup = () => {
        setIsVisible(false);
    };

    // const resetClickCount = () => {
    //   setClickCount(5);
    // };


    // useEffect(() => {
    //   const listener = rotationValue.addListener(({ value }) => {
    //     // 角度发生变化时的操作
    //     // const angle = (value % 1) * 360; // 将值转换为0到度的范围
    //     const angle = Math.round((value % 1) * 360 * 100) / 100; // 将值转换为0到360度的范围，并
    //     console.log('当前角度:', angle);
    //     setTextRotation(angle)
    //   });

    //   return () => {
    //     rotationValue.removeListener(listener);
    //   };
    // }, [rotationValue]);


    return (
        <View style={styles.container}>
            <ImageBackground
                source={require('../../assets/imgs/luckyWheel/luckyWheel_bg.jpg')}
                style={styles.backgroundImage}
            >
                <View style={{ marginVertical: 50 }}>
                    <Text style={styles.title}>{t('luckyWheel')}</Text>
                    <Text style={styles.cjcs}>{t('remainingLotteryEntries')}：{clickCount}</Text>
                </View>

                {/* <TouchableOpacity onPress={() => resetRotation()}>
          <View>
            <Text style={styles.cjcs}>重置角度</Text>
          </View>
        </TouchableOpacity> */}
                {/*         
        <TouchableOpacity onPress={resetClickCount}>
          <View style={{ marginTop: 10 }}>
            <Text style={styles.cjcs}>重置抽奖次数</Text>
          </View>
        </TouchableOpacity> */}

                <View style={styles.box}>
                    {/* <Animated.Image
            source={require('../../assets/imgs/luckyWheel/img_bg.png')}
            style={[styles.centerImage, animatedStyle]}
          /> */}

                    <Animated.View style={[styles.wheelContainer, animatedStyle]}>
                        <Image source={require('../../assets/imgs/luckyWheel/img_bg.png')} style={styles.wheelContainer} />
                        <View style={styles.rewardContainer}>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `70deg` }, { translateX: -105 }, { translateY: -40 },], }]}>{t('prizelevel.1')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `30deg` }, { translateX: -55 }, { translateY: -128 },], }]}>{t('prizelevel.8')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-28deg` }, { translateX: 110 }, { translateY: -168 },], }]}>{t('prizelevel.7')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-65deg` }, { translateX: 205 }, { translateY: -82 },], }]}>{t('prizelevel.6')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-109deg` }, { translateX: 235 }, { translateY: 75 },], }]}>{t('prizelevel.5')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-150deg` }, { translateX: 145 }, { translateY: 222 },], }]}>{t('prizelevel.4')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-198deg` }, { translateX: -55 }, { translateY: 260 },], }]}>{t('prizelevel.3')}</Animated.Text>
                            <Animated.Text style={[styles.rewardText, { transform: [{ rotate: `-250deg` }, { translateX: -246 }, { translateY: 92 },], }]}>{t('prizelevel.2')}</Animated.Text>
                        </View>

                    </Animated.View>

                    <Text onPress={putreceive} style={{ top: 90, fontSize: 18, color: '#fff', zIndex: 999, position: 'absolute', marginTop: -15 }}>{t('start')}</Text>

                    <TouchableOpacity onPress={putreceive} style={{ transform: [{ translateY: -185 }] }}>
                        <Image
                            source={require('../../assets/imgs/luckyWheel/img_pointer.png')}
                            style={styles.pointerImage}
                        />
                    </TouchableOpacity>

                </View>
            </ImageBackground>


            <Modal visible={isVisible} animationType="slide" transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.popupContainer}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ color: '#fff', flex: 1 }}></Text>
                            <TouchableOpacity onPress={closePopup}>
                                <AntDesign name="closecircleo" size={22} color="#fff" />
                            </TouchableOpacity>
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontSize: 22, color: '#fff' }}>{t('Congratulationsonwinning')} </Text>
                            {/* <Text style={{fontSize:22,color:'#fff'}}>谢谢惠顾</Text> */}
                            <Text style={{ fontSize: 18, color: '#fff', marginVertical: 10 }}>{bonus}</Text>

                            <TouchableOpacity onPress={closePopup}>
                                <View style={{ backgroundColor: '#759BFC', borderRadius: 10, padding: 10, paddingHorizontal: 40 }}>
                                    <Text style={{ fontSize: 16, color: '#fff' }}>{t('confirm')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                    </View>
                </View>
            </Modal>


        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    backgroundImage: {
        flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        position: 'absolute',
    },
    wheelContainer: {
        // borderWidth:1,
        // borderColor:'red',
        width: 250,
        height: 250,
    },
    centerImage: {
        width: 250,
        height: 250,
        resizeMode: 'contain',
        marginTop: 100,
    },
    pointerImage: {
        width: 100,
        height: 100,
        // position: 'relative',
        // top: -190,
        resizeMode: 'contain',

    },
    title: {
        fontSize: 40,
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center',
        zIndex: 999,
        textShadowColor: 'rgba(0, 0, 0, 0.5)', // 设置阴影颜色
        textShadowOffset: { width: 2, height: 2 }, // 设置阴影偏移量
        textShadowRadius: 4, // 设置阴影半径
        transform: [{ translateY: 2 }], // 设置立体效果，向下偏移2个像素
    },
    cjcs: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center',
        zIndex: 999,
    },
    box: {
        // borderWidth: 1,
        // borderColor: 'red',
        height: 270,
        width: 270,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        transform: [{ translateY: 50 }]
        // marginTop: 50,
    },
    rewardText: {
        fontSize: 16,
        // fontWeight: 'bold',
        // marginBottom: 10,
        color: '#fff',
        // transform: [
        //   { rotate: `10deg` },
        //   { translateX: 50 },
        //   { translateY: -180 },
        // ],
    },
    rewardContainer: {

    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    popupContainer: {
        backgroundColor: '#3B26CB',
        padding: 20,
        borderRadius: 10,
        width: 250, // 设置弹窗宽度
        // height: 350, // 设置弹窗高度
    },
});

export default WheelOfFortune;