import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Platform, TouchableOpacity, Linking, ActivityIndicator } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import SpliteLine from '../components/spliteLine';
import { commonStyle } from '../components/style';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-community/async-storage';
import { navigateRequireLogin } from '../components/util';
import { getWebsiteInfo } from '../api/apis';
import { commonLang } from '../components/config';
import { WebView } from 'react-native-webview';

const Customer = () => {
    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const [kefu, setKefu] = useState(false);

    const [loading, setLoading] = useState(true);

    const languages = commonLang.faq;

    useEffect(() => {
        init()

    }, []);

    useFocusEffect(
        React.useCallback(() => {

        }, [])
    );

    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            console.log(response.Data.sl)
            setKefu(response.Data.sl)
        }
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            // backgroundColor: commonStyle.bgColor,
            // alignItems: 'center',
        },

    });

    return (
        <View style={styles.container}>
            {Platform.OS == 'web' && kefu && <iframe src={kefu} width="100%" height="100%" style={{ border: '0px' }} />}
            {Platform.OS != 'web' && kefu &&
                <WebView
                    source={{ uri: kefu }}
                    style={{ flex: 1 }}
                    onLoadStart={() => setLoading(true)}
                    onLoad={() => setLoading(false)}
                    onError={() => setLoading(false)}
                />
            }
        </View>
    );
};

export default Customer;