import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

//顶部导航
const TopNavigationBar = ({ title }) => {
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.backButtonContainer}>
                <AntDesign name="arrowleft" size={15} color="white" onPress={() => navigation.goBack()} />
                {/* <FontAwesome name="arrow-left" size={15} color="white" onPress={() => navigation.goBack()} /> */}
            </TouchableOpacity>
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        backgroundColor: '#1B1D29',
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        paddingHorizontal: 15,
        height: '100%',
        justifyContent: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#13B777',
    },
});

export default TopNavigationBar;