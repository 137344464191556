import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Button } from 'react-native';
import { commonStyle } from '../components/style';
import { useTranslation } from 'react-i18next'
import { commonLang } from '../components/config';

const Language = () => {

    const { i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const languages = commonLang.lang

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language)
    };

    return (
        <View style={styles.container}>
            <View style={{ marginTop: 20 }}></View>
            {Object.keys(languages).map((key) => (
                <TouchableOpacity
                    key={key}
                    onPress={() => handleLanguageSelect(key)}
                    style={[
                        styles.languageItem,
                        selectedLanguage === key && styles.selectedLanguage,
                    ]}
                >
                    <Text
                        style={[
                            styles.languageText,
                            selectedLanguage === key && styles.selectedText,
                        ]}
                    >
                        {languages[key]}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'flex-start',
        // justifyContent: 'center',
        paddingHorizontal: 20,
    },
    languageItem: {
        marginBottom: 20,
    },
    //   selectedLanguage: {
    //     backgroundColor: '#00FF00',
    //     borderRadius: 5,
    //   },
    languageText: {
        color: '#828B9F',
        fontSize: 16,
    },
    selectedText: {
        color: '#13B777',
    },
});

export default Language;