import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Dimensions, ScrollView, Modal, TextInput, ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import { navigateRequireLogin } from '../components/util';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { getyuebao, getWebsiteInfo, getwithdrawDetermine } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import SpliteLine from '../components/spliteLine'

if(Dimensions.get('window').width > 500){
    Dimensions.get('window').width = 500
}
const screenWidth = Dimensions.get('window').width - 40;

const itemMargin = 8; // margin between items
const itemWidth = (screenWidth - itemMargin * 5) / 4; // 5 margins for 4 items in a row


const App = () => {
    const { i18n, t } = useTranslation();
    const navigation = useNavigation();
    const toastRef = useRef(null)

    const [modalVisible, setModalVisible] = useState(false);

    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [cycleValue, setCycleValue] = useState('');
    const [cycleId, setCycleId] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); //提交成功返回状态
    const [progress, setProgress] = useState(0); //进度条
    const [isRunning, setIsRunning] = useState(false); //是否开始倒计时
    const [seconds, setSeconds] = useState(60);
    const [staticDomain, setStaticDomain] = useState('');
    const [datalist, setDatalist] = useState();
    const defaultSeconds = 5;

    useEffect(() => {

        getWebsiteInfo().then(response => {
            if (response.status == 1) {
                setStaticDomain(response.Data.static)
            }
        }).catch(error => {
            console.error(error);
        });

        setLoadingMore(true)
        getyuebao('defi').then(response => {
            setLoadingMore(false)

            console.log(itemWidth,screenWidth,screenWidth)

            if (response.status == 1) {
                const updateData = response.Data.map((item) => {
                    item.cycleSelected = item.data[0].id
                    item.interest = item.data[0].interest
                    return item;
                })

                setDatalist(updateData)
            }
        }).catch(error => {
            setLoadingMore(false)
            console.error(error);
        });

       
    }, []);


    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View>
                    <TouchableOpacity onPress={() => goToMiningRecordScreen()}>
                        <Text style={{ color: '#fff', marginRight: 10, fontSize: 16 }}>{t('investmentRecords')}</Text>
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [navigation]);


    useEffect(() => {
        let interval;

        if (isRunning && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
                setProgress((progress) => progress + 100 / defaultSeconds)
            }, 1000);
        }

        console.log('progress', progress)
        if (progress >= 100) {

            setTimeout(() => {
                setIsRunning(false);
                clearInterval(interval);
                console.log('去订单页')
                setIsSuccess(false)
                setAmount('')
                closeModal()
                setProgress(0)
                goToMiningRecordScreen()
            }, 1000);


        }

        return () => {
            clearInterval(interval);
        };
    }, [isRunning, seconds, progress]);

    const goToMiningRecordScreen = () => {
        const route = {
            name: 'MiningRecord',
            params: {},
        };
        navigateRequireLogin(navigation, route);

    };


    const openModal = () => {
        setModalVisible(true);
    }

    const closeModal = () => {
        setModalVisible(false);
        setSeconds(defaultSeconds);
        setIsSuccess(false)
        setIsRunning(false)
        setProgress(0)
    }

    const cycleSelected = (key, cycle, interest,id) => {
        const updateData = datalist.map((item, index) => {
            if (index === key) {
                item.cycleSelected = id;
                item.interest = interest
                setCycleValue(cycle)
                setCycleId(id)
            }
            return item;
        })
        setDatalist(updateData);
    }

    const confirmOrder = async (key,cycle) => {
        console.log('下单',key,cycle)

        setCycleId(cycle)

        const userinfo = await AsyncStorage.getItem('userinfo');
        if (!JSON.parse(userinfo)) {
            navigation.navigate('Login')
            return
        }

        console.log(datalist[key].Currency, datalist[key].cycleSelected)
        setTitle(datalist[key].Currency)
        setAmount('')
        openModal()

    }

    const handleAmountChanged = (text) => {
        text = text.replace(/[^0-9]/g, '');
        setAmount(text)
    }

    const submit = async () => {

        if(isSubmit){
            return false
        }


        if (amount <= 0) {
            showAlert(t('invalidAmount'));
            return;
        }

        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount)) {
            showAlert(t('invalidAmount'));
            return;
        }

        const params = {
            Withdrawal_Amount: amount,
            EarnWalletId: cycleId,
            channel:"earnWallet"
        }

        const jsonString = JSON.stringify(params);

        setIsSubmit(true)
        try {
            const response = await getwithdrawDetermine(jsonString);
            setIsSubmit(false)
            if (response.status == '1') {
               
                startCountdown()
            }
            if (response.status == '0') {
                showAlert(response.Data.msg);
            }
        } catch (error) {
            setIsSubmit(false)
            console.log(`请求发生错误`);
            console.log(error)
        }


    }

    const startCountdown = () => {
        setIsSuccess(true)
        setIsRunning(true);
        setSeconds(defaultSeconds); // 重置剩余秒数为初始值
    };

    return (
        <View style={styles.container}>

            {loadingMore && <ActivityIndicator color={commonStyle.green} />}

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '100%' }}
            >
                {datalist && datalist.map((item, index) => (
                    <View style={styles.sectionContainer} key={index}>
                        <View style={styles.rowContainer}>
                            <View style={styles.currencyContainer}>
                                <Image style={styles.currencyImage} source={{ uri: staticDomain + '/' + item.icon }} />
                                <Text style={styles.currencyText}>{item.Currency}</Text>
                            </View>
                            <View>
                                <Text style={styles.percentText}>{item.interest}%</Text>
                            </View>
                        </View>

                        <Text style={styles.grayText}>{t('rateOfReturn')}</Text>
                        <SpliteLine />
                        <Text style={[styles.whiteText,{marginTop:5}]}>{t('cycle')}</Text>

                        <View style={styles.buttonRow}>
                            {item.data.map((cycle) => (
                                <TouchableOpacity key={cycle.id} style={[styles.buttonItem, item.cycleSelected == cycle.id ? styles.buttonItemSelected : null]} onPress={() => cycleSelected(index, cycle.day, cycle.interest,cycle.id)}>
                                    <Text style={styles.buttonItemText}>{cycle.day}{t('day')}</Text>
                                </TouchableOpacity>
                            ))}
                        </View>

                        <TouchableOpacity style={styles.button} onPress={() => confirmOrder(index,item.cycleSelected)}>
                            <Text style={styles.buttonText}>{t('confirmOrder')}</Text>
                        </TouchableOpacity>

                    </View>

                ))}

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    style={{maxWidth:500}}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <AntDesign name="down" onPress={() => { closeModal() }}
                                    size={24} color="#707070" style={styles.modelDown}
                                />
                            </View>

                            <View style={{ marginHorizontal: 20 }}>
                                <Text style={{ color: '#fff', textAlign: 'center', fontSize: 18 }}>{title}</Text>


                                {isRunning && <View style={{ marginBottom: 50 }}>
                                    <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                                        <AnimatedCircularProgress
                                            size={160}
                                            width={10}
                                            fill={progress}
                                            tintColor={commonStyle.green}
                                            backgroundColor="#cccccc"
                                            rotation={0}
                                            lineCap="round"

                                        >
                                            {(fill) => <View style={{ justifyContent: 'center', alignItems: 'center', borderRadius: 999, backgroundColor: '#222430', height: 140, width: 140, }}>
                                                <Text style={{ color: '#fff', fontSize: 30 }}>{seconds} s</Text>
                                                <Text style={{ color: '#fff', fontSize: 16 }}>{t('amount')}</Text>
                                                <Text style={{ color: '#f5465c' }}>{amount == '' ? amount : Number(amount).toFixed(2)}</Text>
                                            </View>
                                            }
                                        </AnimatedCircularProgress>



                                    </View>

                                    <View style={{ flex: 1, marginHorizontal: 0 }}>
                                        <TouchableOpacity style={[styles.button]} onPress={() => closeModal()}>
                                            <Text style={{ color: '#fff', textAlign: 'center' }}>{t('continueOrder')}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>}


                                {!isRunning && <View style={{ marginTop: 20 }}>
                                    <TextInput
                                        style={styles.input}
                                        placeholder={t('amount')}
                                        value={amount}
                                        onChangeText={handleAmountChanged}
                                        underlineColorAndroid="transparent"
                                        placeholderTextColor={commonStyle.green} />
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View style={{ flex: 1, marginRight: 10 }}>
                                            <TouchableOpacity style={[styles.button, { backgroundColor: 'gray' }]} onPress={() => { closeModal() }}>
                                                <Text style={{ color: '#fff', textAlign: 'center' }}>{t('cancel')}</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ flex: 1, marginHorizontal: 0 }}>
                                            <TouchableOpacity style={styles.button} onPress={() => submit()}>
                                                <Text style={{ color: '#fff', textAlign: 'center' }}>{t('confirm')}</Text>
                                            </TouchableOpacity>
                                        </View>


                                    </View>
                                </View>}


                            </View>

                        </View>
                    </View>
                </Modal>


            </ScrollView >
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        margin: 0,
    },
    sectionContainer: {
        marginHorizontal: 10,
        backgroundColor: '#222430',
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 20,
        marginTop: 10,
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    currencyContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    currencyImage: {
        borderRadius: 999,
        width: 20,
        height: 20,
        marginRight: 10,
    },
    currencyText: {
        color: '#fff',
        fontSize: 18,
    },
    percentText: {
        color: '#f5465c',
        fontSize: 18,
    },
    whiteText: {
        color: '#fff',
    },
    grayText: {
        color: 'gray',
        marginTop: 5,
        marginBottom: 5
    },
    buttonRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginHorizontal: itemMargin / 2,
    },
    buttonItem: {
        borderRadius: 5,
        flexGrow: 0,
        height: 40,
        margin: itemMargin / 2,
        backgroundColor: commonStyle.green,
        width: itemWidth,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonItemSelected: {
        borderWidth: 2,
        borderColor: 'yellow'
    },
    buttonItemText: {
        color: 'white',
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '100%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        marginTop: 0,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430'
        // borderBottomColor: 'transparent'
    },
});

export default App;
