import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast from 'react-native-easy-toast'
import AsyncStorage from '@react-native-community/async-storage';
import { getWebsiteInfo, addbankcard, getcarddetail } from '../api/apis';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { showAlert } from '../components/showAlert';

const AddBankCard = (props) => {

    const { t } = useTranslation();

    // 在组件中调用 useNavigation() 钩子获取导航对象
    const navigation = useNavigation();

    console.log(props.route.params)

    // const [curr, setCurr] = useState('USD');
    const toastRef = useRef(null);
    const [isEdit, setIsEdit] = useState(props.route.params.isEdit);
    const [selectBank, setSelectBank] = useState([]);
    const [password, setPassword] = useState('');
    const [inputBankName, setInputBankName] = useState('USDT(trc20)');
    const [modalVisible, setModalVisible] = useState(false);
    const [name, setName] = useState('');
    const [bankId, setBankId] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [confirmCardNumber, setConfirmCardNumber] = useState('');
    const [nameDisabledid, setNameDisabledid] = useState(false);

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.title,
            headerStyle: {
                backgroundColor: commonStyle.bgColor,
                borderBottomWidth: 0
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);

    const openModal = (type) => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const handleSelectBank = (value) => {
        setInputBankName(value)
        setModalVisible(false);
    }

    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr


            // if (response.Data.curr != "") {
            //     setCurr(response.Data.curr)
            // }
            console.log('curr')
            console.log(response.Data.curr)

            let data0 = [
                "BNB",
                "BTC",
                "ETH",
                "USDT(trc20)",
                "USDT(ERC20)",
            ],
                data2 = [
                    "工商银行",
                    "建设银行",
                    "农业银行",
                    "民生银行",
                    "招商银行",
                    "交通银行",
                    "中国银行",
                    "邮政储蓄",
                    "中信银行",
                    "兴业银行",
                    "华夏银行",
                    "浦发银行",
                    "广发银行",
                    "平安银行",
                    "光大银行",
                    "其他",
                ],
                data1 = [
                    "ธนาคารกรุงเทพ", //盤谷銀行
                    "ธนาคารกสิกรไทย", //開泰銀行
                    "ธนาคารกรุงไทย", //泰京銀行
                    "ธนาคารทหารไทย", //軍人銀行 (泰國)
                    "ธนาคารไทยพาณิชย์", //泰國匯商銀行
                    "ธนาคารสแตนดาร์ดชาร์เตอร์ด", //渣打銀行
                    "ธนาคารยูโอบี", //大華銀行
                    "ธนาคารกรุงศรีอยุธยา", //大城銀行
                    "ธนาคารออมสิน", //政府儲蓄銀行
                    "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", //泰國進出口銀行
                    "ธนาคารธนชาต", //泰納昌銀行
                    "ธนาคารทิสโก้", //鐵士古銀行
                    "ธนาคารเกียรตินาคิน", //甲那金銀行
                    "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", //农业银行和农业合作社
                    "ธนาคารซีไอเอ็มบีไทย", // 泰国联昌银行
                    "ธนาคารทหารไทยธนชาต", //TTB
                    "ธนาคาร แลนด์ แอนด์ เฮ้าส์", //LHBANK
                    "ธนาคารอาคารสงเคราะห์", //GHB
                    "ธนาคารไอซีบีซี (ไทย)", //ICBC 中国工商银行
                    "ธนาคารไทยเคดิต เพื่อรายย่อย", //TCRB
                    "ธนาคารมิซูโฮ คอร์ปอเรต", // MHCB
                    "ธนาคารอิสลามแห่งประเทศไทย", //ISBT
                    "ธนาคารดอยซ์แบงก์ เอจี", //DBBK
                    "ธนาคารแห่งประเทศจีน(ไทย)จำกัด(มหาชน)", //中國銀行（泰國）股份有限公司
                    "ธนาคารชูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น", //(三井住友銀行)
                    "ธนาคารซิตี้แบงก์", //(花旗(泰國)銀行)  "", // 花旗银行
                    "อื่นๆ", // 其他
                ],
                data3 = [
                    "Vietcombank",
                    "ACB",
                    "DongABank",
                    "Techcombank",
                    "Vietinbank",
                    "Sacombank",
                    "BIDV",
                    "Agribank",
                    "Eximbank",
                    "SCB",
                    "VietABank",
                    "ABBank",
                    "Saigonbank",
                    "PG Bank",
                    "Oceanbank",
                    "NamABank",
                    "BacABank",
                    "VIB",
                    "VPBank",
                    "GPBank",
                    "MariTimeBank",
                    "HDBank",
                    "OCB",
                    "TienPhongBank",
                    "Vietbank",
                    "Seabank",
                    "MBBank",
                    "NASBank",
                    "Quoc Dan(NCB)",
                    "SHB",
                    "KienlongBank ",
                    "shinhanbank",
                    "WOORI BANK",
                    "MSB",
                    "LienVietPostBank",
                    "IVB BANK",
                    "CBBANK",
                    "VietCapitalBank",
                    "BaoVietBank",
                    "VDB",
                    "HSBC",
                    "UBank",
                    "CIMB",
                ],
                data4 = [
                    "capitec",
                    "Absa",
                    "FNB",
                    "Nedbank",
                    "tymeBank",
                    "Standard Bank",
                    "standardbank",
                    "absa",
                    "fnb",
                    "nedbank",
                ],
                data5 = [
                    "Access Bank Nigeria",
                    "Zenith Bank Nigeria",
                    "First Bank of Nigeria",
                    "United Bank for Africa",
                    "Guaranty Trust Bank",
                    "Stanbic IBTC Bank",
                    "Eco Bank Nigeria",
                    "Fidelity Bank",
                    "Union Bank of Nigeria",
                    "First City Monument Bank",
                    "Providus Bank",
                    "sterling bank plc",
                ],
                data6 = [
                    "ifsc Bank",
                ],
                data7 = [
                    "EMAIL",
                    "PHONE",
                    "CPF",
                    "CNPJ",
                    "RANDOM",
                ],
                data8 = [
                    "HSBC",
                    "BANAMEX",
                    "Banco Azteca",
                    "SANTANDER",
                    "Scotia bank",
                    "BANORTE",
                    "BBVA Bancomer",
                    "Bancomext",
                    "Inbursa",
                    "Deutsche Bank",
                    "Banco del Bajio",
                    "BanCoppel",
                ],

                data9 = [
                    "Bank Aceh Syariah",
                    "Bank Agris UUS",
                    "BPD ISTIMEWA ACEH SYARIAH",
                    "Bank IBK Indonesia",
                    "BANK AMAR INDONESIA",
                    "Bank Agroniaga",
                    "Bank Andara",
                    "Anglomas International Bank",
                    "BANK ANTAR DAERAH",
                    "Bank ANZ Indonesia",
                    "Bank ANZ PANIN",
                    "ARTAJASA PEMBAYARAN ELEKTRONIK",
                    "Bank Arta Niaga Kencana",
                    "Bank Artha Graha Internasional",
                    "Bank ARTOS/ Bank Jago",
                    "BPD Bali",
                    "Bank Bisnis Internasional",
                    "Bangkok Bank",
                    "BPD Banten",
                    "BANK BARCLAYS INDONESIA",
                    "Bank Central Asia",
                    "Bank Central Asia (BCA) Syariah",
                    "Bank Bengkulu",
                    "Bank Jawa Barat(BJB)",
                    "Bank BJB Syariah",
                    "Bank Negara Indonesia(BNI)",
                    "Bank BNI Syariah",
                    "BANK OF CHINA LIMITED",
                    "Bank Rakyat Indonesia(BRI)",
                    "Bank BRI Syariah",
                    "Bank BNP Paribas",
                    "BANK OF AMERICA NA",
                    "BPR KS",
                    "Bank Syariah Indonesia(BSI)",
                    "Bank Tabungan Negara (BTN)",
                    "Bank Tabungan Negara (BTN) UUS",
                    "Bank BTPN",
                    "BTPN Syariah",
                    "Bank BTPN Syariah",
                    "Wokee/Bukopin",
                    "Bank Bukopin Syariah",
                    "Bank Bumi Arta",
                    "BANK BUMIPUTERA",
                    "Bank Capital Indonesia",
                    "BANK CENTRATAMA",
                    "BANK CHINA CONSTRUCTION",
                    "CTBC Indonesia",
                    "Centratama Nasional Bank(CNB)",
                    "Bank CIMB Niaga",
                    "Bank CIMB Niaga UUS",
                    "Bank CIMB Niaga REKENING PONSEL",
                    "Citibank",
                    "Bank Commonwealth",
                    "BPD DIY",
                    "BANK PEMBANGUNAN DAERAH DIY UNIT USAHA SYARIAH",
                    "Bank Danamon",
                    "Bank Danamon UUS",
                    "Bank DBS Indonesia",
                    "Deutsche Bank",
                    "Bank Dinar Indonesia",
                    "BANK DIPO INTERNATIONAL",
                    "Bank DKI",
                    "Bank DKI UUS",
                    "Bank EKA",
                    "BANK EKONOMI RAHARJA",
                    "Bank Fama International",
                    "Bank Ganesha",
                    "LINE Bank/KEB Hana",
                    "Allo Bank/Bank Harda Internasional",
                    "Bank Himpunan Saudara 1906",
                    "HSBC",
                    "Bank ICBC Indonesia",
                    "Bank Ina Perdana",
                    "Bank Index Selindo",
                    "Bank of India Indonesia",
                    "BANK JAGO TBK",
                    "Bank Jambi",
                    "Bank Jasa Jakarta",
                    "Bank Jateng",
                    "BPD JAWA TENGAH UNIT USAHA SYARIAH",
                    "Bank Jatim",
                    "BPD Jawa Timur",
                    "Bank Jatim UUS",
                    "JPMORGAN CHASE BANK",
                    "Bank JTrust Indonesia",
                    "BPD Kalimantan Barat/Kalbar",
                    "BPD Kalimantan Barat UUS",
                    "BPD Kalimantan Selatan/Kalsel",
                    "BPD Kalimantan Selatan UUS",
                    "BPD Kalimantan Tengah (Kalteng)",
                    "BPD Kalimantan Timur",
                    "BPD Kalimantan Timur UUS",
                    "Seabank/Bank Kesejahteraan Ekonomi(BKE)",
                    "BPD Lampung",
                    "Bank Maluku",
                    "Bank Mandiri",
                    "Bank Syariah Mandiri",
                    "Bank Mandiri Taspen Pos",
                    "Bank MANTAP",
                    "Bank Multi Arta Sentosa(MAS)",
                    "Bank Maspion Indonesia",
                    "Bank Mayapada",
                    "Bank Maybank",
                    "Bank Maybank Syariah Indonesia",
                    "Bank Maybank Syariah Indonesia UUS",
                    "Bank Mayora Indonesia",
                    "Bank Mega",
                    "Bank Mega Syariah",
                    "Bank Mestika Dharma",
                    "BANK METRO EXPRESS",
                    "Motion/Bank MNC Internasional",
                    "Bank Muamalat Indonesia",
                    "Bank Mitra Niaga",
                    "Bank Mizuho Indonesia",
                    "Bank MUTIARA",
                    "Bank MULTICOR",
                    "Bank National Nobu",
                    "BANK NIAGA TBK. SYARIAH",
                    "BPD Nusa Tenggara Barat(NTB)",
                    "BPD Nusa Tenggara Barat (NTB) UUS",
                    "BPD Nusa Tenggara Timur(NTT)",
                    "Bank Nusantara Parahyangan",
                    "Bank OCBC NISP",
                    "Bank OCBC NISP UUS",
                    "Bank Panin",
                    "Panin Dubai Syariah",
                    "Bank Papua",
                    "Bank Permata",
                    "Bank Permata UUS",
                    "Bank Prima Master",
                    "BANK PUNDI INDONESIA",
                    "QNB KESAWAN",
                    "QNB Indonesia",
                    "Bank Oke Indonesia",
                    "Rabobank International Indonesia",
                    "Bank Resona Perdania",
                    "BPD Riau Dan Kepri",
                    "BPD Riau Dan Kepri UUS",
                    "Blu/BCA Digital",
                    "BANK PURBA DANARTA",
                    "Bank Sahabat Sampoerna",
                    "Bank SBI Indonesia",
                    "Bank Shinhan Indonesia",
                    "Bank Sinarmas",
                    "Bank Sinarmas UUS",
                    "Standard Chartered Bank",
                    "Bank Sulteng",
                    "Bank Sultra",
                    "Bank Sulselbar",
                    "Bank Sulselbar UUS",
                    "BPD Sulawesi Utara(SulutGo)",
                    "BPD Sumatera Barat",
                    "BPD Sumatera Barat UUS",
                    "BANK NAGARI",
                    "BPD Sumsel Babel",
                    "Bank Sumsel Babel",
                    "Bank Sumsel Dan Babel UUS",
                    "Bank Sumut",
                    "Bank Sumut UUS",
                    "Bank Sumitomo Mitsui Indonesia",
                    "Bank of Tokyo",
                    "TMRW/Bank UOB Indonesia",
                    "Bank Victoria International",
                    "Bank Victoria Syariah",
                    "Bank Woori Saudara",
                    "Neo Commerce/Bank Yudha Bhakti(BNC)",
                    "BPD_Daerah_Istimewa_Yogyakarta_(DIY)",
                    "CCB Indonesia",
                    "Royal Bank of Scotland (RBS)",
                    "OVO",
                    "DANA",
                    "GOPAY",
                    "SHOPEEPAY",
                    "LINKAJA",
                ],

                datas = [];

            if (curr == "ZAR") {
                datas = data4;
            } else if (curr == "CNY") {
                datas = data2;
            } else if (curr == "VND") {
                datas = data3;
            } else if (curr == "THB") {
                datas = data1;
            } else if (curr == "NGN") {
                datas = data5;
            } else if (curr == "INR") {
                datas = data6;
            } else if (curr == "BRL") {
                datas = data7;
            }else if (curr == "MXN") {
                datas = data8;
            }else if(curr == "IDR"){
                datas = data9;
            }else if (curr == "USD") {
                datas = data8;
            }

            

            for (let i = 0; i < data0.length; i++) {
                if (data0[i] != "") {
                    datas.unshift(data0[i]);
                }
            }

            setSelectBank(datas)
            // console.log(datas)

            if (isEdit) {
                setBankId(props.route.params.id)
                const response = await getcarddetail(props.route.params.id);
                // console.log(response)
                if (response.status == '1') {
                    // console.log('银行卡详情成功')
                    // console.log(response.Data1.item)
                    setName(response.Data1.item[0].user_name)
                    setInputBankName(response.Data1.item[0].user_bank_card_bank_name)

                    if (response.Data1.item[0].user_bank_card_number != "") {
                        setCardNumber(response.Data1.item[0].user_bank_card_number)
                        if (curr == "INR") {
                            let Bankin = this.BankNum.split(';');
                            setCardNumber(Bankin[0])
                        }
                        if (curr == "BRL") {
                            let Bankin = this.BankNum.split(';');
                            setCardNumber(Bankin[0])
                        }
                    }

                    if (response.Data1.Realnameverification == "1") {
                        //有实名验证，禁用
                        setNameDisabledid(true)
                    }

                } else {
                    console.log('银行卡详情失败')
                }
            }
        }

    }

    const handleNameChanged = (text) => {
        setName(text);
    };

    const handleCardNumberChanged = (text) => {
        // const filteredText = text.replace(/[^0-9]/g, '');
        // setCardNumber(filteredText);
        setCardNumber(text);
    };

    const handleConfirmCardNumberChanged = (text) => {
        setConfirmCardNumber(text);
    };

    const handlePasswordChanged = (text) => {
        setPassword(text)
    };

    const handleConfirm = () => {

        if (name === '') {
            // if(inputBankName != "USDT(trc20)" && inputBankName != "USDT(ERC20)" && inputBankName != "BNB" && inputBankName != "ERC"){
                toastRef.current.show(t('pleaseEnterName'));
                return;
            // }
        }
        if (cardNumber === '') {
            toastRef.current.show(t('pleaseEnterCardNumber'));
            return;
        }
        if (confirmCardNumber != cardNumber) {
            toastRef.current.show(t('confirmCardNumberInconsistency'));
            return;
        }
        if (inputBankName === '') {
            toastRef.current.show(t('pleaseEnterBankName'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterSecurityPassword'));
            return;
        }


        const edit = isEdit ? "1" : "2";
        let data_post = {
            Account_opening_branch: "",
            Address_: "",
            BankNum: cardNumber,
            InputBankName: inputBankName,
            RealName: name,
            SafePassword: password,
            edit: edit
        };

        if (isEdit == 1) {
            data_post.BankCardID = bankId;
        }
        data_post = JSON.stringify(data_post);

        // const edit = isEdit ? "1" : "2"
        // let data_post = `{"Account_opening_branch":"","Address_":"","BankNum":"${cardNumber}","InputBankName":"${inputBankName}","RealName":"${name}","SafePassword":"${password}","edit":"${edit}"}`;
        // if (isEdit == 1) {
        //     data_post = `{"Account_opening_branch":"","Address_":"","BankNum":"${cardNumber}","InputBankName":"${inputBankName}","RealName":"${name}","SafePassword":"${password}","edit":"${edit}","BankCardID":"${bankId}"}`;
        // }

        addbankcard(data_post).then(response => {
            if (response.status == 1) {

                showAlert(response.msg, () => {
                    navigation.goBack()
                });

                // toastRef.current.show(response.msg);
                // navigation.goBack()
            } else {
                console.log(response)
                toastRef.current.show(response.Data.msg);
            }
        }).catch(error => {
            // console.error(error);
        });

    };


    return (
        <View style={styles.container}>
            <View style={styles.form}>
                {/* {inputBankName != "USDT(trc20)" && inputBankName != "USDT(ERC20)" && inputBankName != "BNB" && inputBankName != "ERC" &&  */}
                <View>
                    <Text style={styles.label}>{t('name')}</Text>
                    <TextInput
                        style={styles.input}
                        value={name}
                        disabled={nameDisabledid}
                        placeholder={t('pleaseEnterName')}
                        onChangeText={handleNameChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>
                {/* } */}


                <View>
                    <Text style={styles.label}>{t('cardNumber')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterCardNumber')}
                        value={cardNumber}
                        onChangeText={handleCardNumberChanged}
                        keyboardType="numeric"
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('confirmCardNumber')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterCardNumber')}
                        value={confirmCardNumber}
                        onChangeText={handleConfirmCardNumberChanged}
                        keyboardType="numeric"
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('paymentMethod')}</Text>
                    <TouchableOpacity onPress={openModal}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={styles.input}>{inputBankName}</Text>
                            <View style={styles.iconText}>
                                <View><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('securityPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterSecurityPassword')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

            </View>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>

                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >
                            <View>
                                {selectBank.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleSelectBank(item)}>
                                        <Text style={[styles.modalText]}>{item}</Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 10,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent'
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 40,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    bottonNavText: {
        color: '#707070',
        fontSize: 16
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: -10
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '70%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    }
});

export default AddBankCard;
