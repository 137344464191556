import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { commonStyle } from '../components/style'


//顶部导航
const TopNavigationBar = ({ title ,previousRoute}) => {
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    // console.log('上一页',previousRoute)

    const goback = () =>{
        if(previousRoute == 'MainNav'){
            navigation.navigate('MainNav', { screen: 'Home' })
        }else{
            navigation.goBack()
        }
    }

    return (
        <View style={[styles.container,{height:90+insets.top}]}>
            <TouchableOpacity style={styles.backButtonContainer}>
                <AntDesign name="arrowleft" size={22} color="white" onPress={() => goback() } />
            </TouchableOpacity>
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // height: 100,
        backgroundColor: commonStyle.bgColor,
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        paddingHorizontal: 15,
        height: '100%',
        justifyContent: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#fff',
    },
});

export default TopNavigationBar;