import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image,createStackNavigator  } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import NetInfo from "@react-native-community/netinfo";
import { useTranslation } from 'react-i18next';

const NetWork = () => {

  const navigation = useNavigation();
  const { i18n, t } = useTranslation();

  useEffect(() => {

  }, []);

  const retry = () => {

    NetInfo.addEventListener((state) => {
      if (state.isConnected) {
        // 有网络连接，继续执行您的逻辑
        navigation.navigate('MainNav', { screen: 'Home' });
      } else {
        // 无网络连接，跳转到另一个页面
        navigation.navigate('NetWork');
      }
    });
   
  }

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={require('../../assets/imgs/common/network_err.png')} />
      <Text style={{ color: '#fff' }}>{t('networkError')}</Text>
      <TouchableOpacity style={styles.icon} onPress={() => { retry() }}>
        <Text style={{ color: '#fff', fontSize: 20, borderWidth: 1 }}>{t('clickTetry')}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  image: {

  }
});

export default NetWork;
